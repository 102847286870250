/* eslint-disable @typescript-eslint/no-explicit-any */
export default class SegmentBase {
  pluckValues = (message: any) => {
    if (!message) {
      return "";
    }

    if (typeof message !== "object") {
      return message;
    }

    if (Array.isArray(message)) {
      message.map((item) => {
        return this.pluckValues(item);
      });
    }

    const value =  Object.values(message).map((item) => {
      if (typeof item === "object") {
        this.pluckValues(item);
      }

      const values = this.getValues(item);
      return values;
    });

    if (Array.isArray(value)) {
      const filteredData = value.filter(item => item !== "");
      const resultString = filteredData.join(" ");

      return resultString.replace(/,+/g, ",").replace(/^,|,$/g, "");
    } else {
      return value;
    }
  };

  getValues = (item: any) => {
    if (typeof item === "object") {
      return Object.values(item).map((i) => {
        return i;
      });
    }

    return item;
  };
}
