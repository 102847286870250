import { CurrentUser } from "./User";

export interface UserResponse {
  data: User[] | null;
  error: string | null;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhoneNumber: string;
  }

export default class UserService {
  static async index(currentUser: CurrentUser, accessToken: string | undefined): Promise<UserResponse> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/users`, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
          "Tenant-Slug": currentUser.tenantSlug,
        },
        method: "GET",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const users: User[] = responseData.data;
      return { data: users, error: null };
    } else {
      return { data: null, error: "Fetching current user failed" };
    }
  }
}
