import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import ReferralUpload from "./UploadReferral";
import { useGetOrganisationBySlugQuery } from "../../services/OrganisationService";
import { useGetLocationsQuery } from "../../services/LocationService";
import { Location } from "../../models/Location";
import SelectLocation from "./registration/SelectLocation";
import store from "../../redux/store";

export interface Organisation {
  name: string;
  email: string;
  slug: string;
  country: string;
  website: string;
  address: string;
  landline: string;
  logoUrl: string;
  metadata: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any; // Define the structure of metadata as a key-value pair
  };
}

const urlParams = new URLSearchParams(window.location.search);

const ReferralUploadWrapper = () => {
  const { slug } = useParams<{ slug?: string }>();

  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const locationSlug = urlParams.get("locSlug") || null;
  if (!slug) {
    return <div>Invalid slug</div>;
  }

  const setAndStoreCurrentLocation = (location: Location | null) => {
    console.log("setAndStoreCurrentLocation", location);
    setCurrentLocation(location);
    store.dispatch({ type: "currentLocationState", payload: location });
  };

  const { data, error, isLoading } = useGetOrganisationBySlugQuery(slug);

  const { data: locationsData, isLoading: locationsIsLoading } =
    useGetLocationsQuery({ slug });

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch organisation", error);
      setLoading(false);
      return;
    }

    if (isLoading) {
      return;
    }

    if (!data) {
      console.error("No organisation data found");
      setLoading(false);
      return;
    }

    setOrganisation(data?.data);
    setLoading(false);
  }, [data, error, isLoading]);

  useEffect(() => {
    if (locationSlug && locationsData) {
      const currentLocation = locationsData.data.locations?.find(
        (loc) => loc.slug === locationSlug,
      );
      setAndStoreCurrentLocation(currentLocation || null);
    }

    if (store.getState().currentLocation) {
      setCurrentLocation(store.getState().currentLocation);
    }
  }, [locationSlug, locationsData]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-xl mb-4">Loading</p>
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent" />
      </div>
    );
  }

  if (!organisation) {
    return <Navigate to="/" />;
  }

  return (
    <div className="content-center">
      <div className="mx-auto max-w-[600px]">
        {!loading && (
          <div>
            {organisation && currentLocation === null && (
              <SelectLocation
                organisation={organisation}
                setCurrentLocation={setAndStoreCurrentLocation}
              />
            )}

            {organisation && !locationsIsLoading && currentLocation && (
              <ReferralUpload
                organisation={organisation}
                location={currentLocation}
                setCurrentLocation={setAndStoreCurrentLocation}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralUploadWrapper;
