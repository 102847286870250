export interface CurrentUser {
  email: string;
  tenantSlug: string;
  externalId: string;
  avatarUrl: string;
}

interface DataResponse {
  data: CurrentUser | null;
  error: string | null;
}

export const isSuperAdmin = (currentUser: CurrentUser | null) => {
  return currentUser?.email === "admin@elvati.com";
};

export default class UserAPI {
  static async currentUser(currentUserId: string, accessToken: string | undefined): Promise<DataResponse> {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST_URL}/current_user/${currentUserId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          },
        method: "GET",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const currentUser: CurrentUser = {
        email: responseData.data.email,
        tenantSlug: responseData.data.tenantSlug,
        externalId: responseData.data.externalId,
        avatarUrl: responseData.data.avatarUrl,
      };
      return { data: currentUser, error: null };
    } else {
      return { data: null, error: "Fetching current user failed" };
    }
  }
}