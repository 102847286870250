import SegmentBase from "./SegmentBase";

export default class PV1Segment extends SegmentBase {
  parse(hash: never) {
    return [
      { "field_name": "PV1-1", "field_description": "Set ID - PV1", "value": this.pluckValues(hash["data"]["PV1.1"]) },
      { "field_name": "PV1-2", "field_description": "Patient Class", "value": this.pluckValues(hash["data"]["PV1.2"]) },
      { "field_name": "PV1-3", "field_description": "Assigned Patient Location", "value": this.pluckValues(hash["data"]["PV1.3"]) },
      { "field_name": "PV1-4", "field_description": "Admission Type", "value": this.pluckValues(hash["data"]["PV1.4"]) },
      { "field_name": "PV1-5", "field_description": "Preadmit Number", "value": this.pluckValues(hash["data"]["PV1.5"]) },
      { "field_name": "PV1-6", "field_description": "Prior Patient Location", "value": this.pluckValues(hash["data"]["PV1.6"]) },
      { "field_name": "PV1-7", "field_description": "Attending Doctor", "value": this.pluckValues(hash["data"]["PV1.7"]) },
      { "field_name": "PV1-8", "field_description": "Referring Doctor", "value": this.pluckValues(hash["data"]["PV1.8"]) },
      { "field_name": "PV1-9", "field_description": "Consulting Doctor", "value": this.pluckValues(hash["data"]["PV1.9"]) },
      { "field_name": "PV1-10", "field_description": "Hospital Service", "value": this.pluckValues(hash["data"]["PV1.10"]) },
      { "field_name": "PV1-11", "field_description": "Temporary Location", "value": this.pluckValues(hash["data"]["PV1.11"]) },
      { "field_name": "PV1-12", "field_description": "Preadmit Test Indicator", "value": this.pluckValues(hash["data"]["PV1.12"]) },
      { "field_name": "PV1-13", "field_description": "Re-admission Indicator", "value": this.pluckValues(hash["data"]["PV1.13"]) },
      { "field_name": "PV1-14", "field_description": "Admit Source", "value": this.pluckValues(hash["data"]["PV1.14"]) },
      { "field_name": "PV1-15", "field_description": "Ambulatory Status", "value": this.pluckValues(hash["data"]["PV1.15"]) },
      { "field_name": "PV1-16", "field_description": "VIP Indicator", "value": this.pluckValues(hash["data"]["PV1.16"]) },
      { "field_name": "PV1-17", "field_description": "Admitting Doctor", "value": this.pluckValues(hash["data"]["PV1.17"]) },
      { "field_name": "PV1-18", "field_description": "Patient Type", "value": this.pluckValues(hash["data"]["PV1.18"]) },
      { "field_name": "PV1-19", "field_description": "Visit Number", "value": this.pluckValues(hash["data"]["PV1.19"]) },
      { "field_name": "PV1-20", "field_description": "Financial Class", "value": this.pluckValues(hash["data"]["PV1.20"]) },
      { "field_name": "PV1-21", "field_description": "Charge Price Indicator", "value": this.pluckValues(hash["data"]["PV1.21"]) },
      { "field_name": "PV1-22", "field_description": "Courtesy Code", "value": this.pluckValues(hash["data"]["PV1.22"]) },
      { "field_name": "PV1-23", "field_description": "Credit Rating", "value": this.pluckValues(hash["data"]["PV1.23"]) },
      { "field_name": "PV1-24", "field_description": "Contract Code", "value": this.pluckValues(hash["data"]["PV1.24"]) },
      { "field_name": "PV1-25", "field_description": "Contract Effective Date", "value": this.pluckValues(hash["data"]["PV1.25"]) },
      { "field_name": "PV1-26", "field_description": "Contract Amount", "value": this.pluckValues(hash["data"]["PV1.26"]) },
      { "field_name": "PV1-27", "field_description": "Contract Period", "value": this.pluckValues(hash["data"]["PV1.27"]) },
      { "field_name": "PV1-28", "field_description": "Interest Code", "value": this.pluckValues(hash["data"]["PV1.28"]) },
      { "field_name": "PV1-29", "field_description": "Transfer to Bad Debt Code", "value": this.pluckValues(hash["data"]["PV1.29"]) },
      { "field_name": "PV1-30", "field_description": "Transfer to Bad Debt Date", "value": this.pluckValues(hash["data"]["PV1.30"]) },
      { "field_name": "PV1-31", "field_description": "Bad Debt Agency Code", "value": this.pluckValues(hash["data"]["PV1.31"]) },
      { "field_name": "PV1-32", "field_description": "Bad Debt Transfer Amount", "value": this.pluckValues(hash["data"]["PV1.32"]) },
      { "field_name": "PV1-33", "field_description": "Bad Debt Recovery Amount", "value": this.pluckValues(hash["data"]["PV1.33"]) },
      { "field_name": "PV1-34", "field_description": "Delete Account Indicator", "value": this.pluckValues(hash["data"]["PV1.34"]) },
      { "field_name": "PV1-35", "field_description": "Delete Account Date", "value": this.pluckValues(hash["data"]["PV1.35"]) },
      { "field_name": "PV1-36", "field_description": "Discharge Disposition", "value": this.pluckValues(hash["data"]["PV1.36"]) },
      { "field_name": "PV1-37", "field_description": "Discharged to Location", "value": this.pluckValues(hash["data"]["PV1.37"]) },
      { "field_name": "PV1-38", "field_description": "Diet Type", "value": this.pluckValues(hash["data"]["PV1.38"]) },
      { "field_name": "PV1-39", "field_description": "Servicing Facility", "value": this.pluckValues(hash["data"]["PV1.39"]) },
      { "field_name": "PV1-40", "field_description": "Bed Status", "value": this.pluckValues(hash["data"]["PV1.40"]) },
      { "field_name": "PV1-41", "field_description": "Account Status", "value": this.pluckValues(hash["data"]["PV1.41"]) },
      { "field_name": "PV1-42", "field_description": "Pending Location", "value": this.pluckValues(hash["data"]["PV1.42"]) },
      { "field_name": "PV1-43", "field_description": "Prior Temporary Location", "value": this.pluckValues(hash["data"]["PV1.43"]) },
      { "field_name": "PV1-44", "field_description": "Admit Date/Time", "value": this.pluckValues(hash["data"]["PV1.44"]) },
      { "field_name": "PV1-45", "field_description": "Discharge Date/Time", "value": this.pluckValues(hash["data"]["PV1.45"]) },
    ];
  }
}
