/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { Referral } from "../services/MediaPatient";
import { ReferralStatus } from "../services/ReferralStatusService";
import { User } from "../services/UserService";
import { Organisation } from "../components/referrals/ReferralUploadWrapper";
import { organisationService } from "../services/OrganisationService";
import { Location } from "../models/Location";
import { locationService } from "../services/LocationService";
import { referralStatusService } from "../services/ReferralStatusService";
import { mediaPatientApi } from "../services/MediaPatient";

const persistConfig = {
  key: "root",
  storage,
};

function currentUserReducer(state = {}, action: any) {
  if (action.type === "currentUserState") {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}

const currentLocationState: Location | null = null;
function currentLocationReducer(
  state: Location | null = currentLocationState,
  action: any,
): Location | null {
  if (action.type === "currentLocationState") {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}

const referralState: Referral | null = null;
function referralReducer(
  state: Referral | null = referralState,
  action: any,
): Referral | null {
  if (action.type === "referralState") {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}

const organisationState: Organisation | null = null;
function organisationReducer(
  state: Organisation | null = organisationState,
  action: any,
): Organisation | null {
  if (action.type === "organisationState") {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}

function referralsReducer(state: Referral[] = [], action: any) {
  if (action.type === "referralsState") {
    return action.payload;
  }
  return state;
}

function usersReducer(state: User[] = [], action: any) {
  if (action.type === "usersState") {
    return action.payload;
  }
  return state;
}

function referralStatusesReducer(state: ReferralStatus[] = [], action: any) {
  if (action.type === "referralStatusesState") {
    return action.payload;
  }
  return state;
}

function accessTokenReducer(state = "", action: any) {
  if (action.type === "accessTokenState") {
    return action.payload;
  }
  return state;
}

const reducers = combineReducers({
  organisation: organisationReducer,
  currentUser: currentUserReducer,
  referrals: referralsReducer,
  referralStatuses: referralStatusesReducer,
  users: usersReducer,
  referral: referralReducer,
  currentLocation: currentLocationReducer,
  accessToken: accessTokenReducer,
  [organisationService.reducerPath]: organisationService.reducer,
  [locationService.reducerPath]: locationService.reducer,
  [referralStatusService.reducerPath]: referralStatusService.reducer,
  [mediaPatientApi.reducerPath]: mediaPatientApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      organisationService.middleware,
      locationService.middleware,
      referralStatusService.middleware,
      mediaPatientApi.middleware,
    ),
});
