import SegmentBase from "./SegmentBase";

export default class OBRSegment extends SegmentBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(hash: any) {
    return [
      { "field_name": "OBR-1", "field_description": "Set ID - OBR", "value": this.pluckValues(hash["data"]["OBR.1"]) },
      { "field_name": "OBR-2", "field_description": "Placer Order Number", "value": this.pluckValues(hash["data"]["OBR.2"]) },
      { "field_name": "OBR-3", "field_description": "Filler Order Number", "value": this.pluckValues(hash["data"]["OBR.3"]) },
      { "field_name": "OBR-4", "field_description": "Universal Service ID", "value": this.pluckValues(hash["data"]["OBR.4"]) },
      { "field_name": "OBR-5", "field_description": "Priority", "value": this.pluckValues(hash["data"]["OBR.5"]) },
      { "field_name": "OBR-6", "field_description": "Requested Date/Time", "value": this.pluckValues(hash["data"]["OBR.6"]) },
      { "field_name": "OBR-7", "field_description": "Observation Date/Time", "value": this.pluckValues(hash["data"]["OBR.7"]) },
      { "field_name": "OBR-8", "field_description": "Observation End Date/Time", "value": this.pluckValues(hash["data"]["OBR.8"]) },
      { "field_name": "OBR-9", "field_description": "Collection Volume", "value": this.pluckValues(hash["data"]["OBR.9"]) },
      { "field_name": "OBR-10", "field_description": "Collector Identifier", "value": this.pluckValues(hash["data"]["OBR.10"]) },
      { "field_name": "OBR-11", "field_description": "Specimen Action Code", "value": this.pluckValues(hash["data"]["OBR.11"]) },
      { "field_name": "OBR-12", "field_description": "Danger Code", "value": this.pluckValues(hash["data"]["OBR.12"]) },
      { "field_name": "OBR-13", "field_description": "Relevant Clinical Info.", "value": this.pluckValues(hash["data"]["OBR.13"]) },
      { "field_name": "OBR-14", "field_description": "Specimen Received Date/Time", "value": this.pluckValues(hash["data"]["OBR.14"]) },
      { "field_name": "OBR-15", "field_description": "Specimen Source", "value": this.pluckValues(hash["data"]["OBR.15"]) },
      { "field_name": "OBR-16", "field_description": "Ordering Provider", "value": this.pluckValues(hash["data"]["OBR.16"]) },
      { "field_name": "OBR-17", "field_description": "Order Callback Phone Number", "value": this.pluckValues(hash["data"]["OBR.17"]) },
      { "field_name": "OBR-18", "field_description": "Placer field 1", "value": this.pluckValues(hash["data"]["OBR.18"]) },
      { "field_name": "OBR-19", "field_description": "Placer field 2", "value": this.pluckValues(hash["data"]["OBR.19"]) },
      { "field_name": "OBR-20", "field_description": "Filler Field 1", "value": this.pluckValues(hash["data"]["OBR.20"]) },
      { "field_name": "OBR-21", "field_description": "Filler Field 2", "value": this.pluckValues(hash["data"]["OBR.21"]) },
      { "field_name": "OBR-22", "field_description": "Results Rpt/Status Chng – Date/Time", "value": this.pluckValues(hash["data"]["OBR.22"]) },
      { "field_name": "OBR-23", "field_description": "Charge to Practice", "value": this.pluckValues(hash["data"]["OBR.23"]) },
      { "field_name": "OBR-24", "field_description": "Diagnostic Serv Sect ID", "value": this.pluckValues(hash["data"]["OBR.24"]) },
      { "field_name": "OBR-25", "field_description": "Result Status", "value": this.pluckValues(hash["data"]["OBR.25"]) },
      { "field_name": "OBR-26", "field_description": "Parent Result", "value": this.pluckValues(hash["data"]["OBR.26"]) },
      { "field_name": "OBR-27", "field_description": "Quantity/Timing", "value": this.pluckValues(hash["data"]["OBR.27"]) },
      { "field_name": "OBR-28", "field_description": "Result Copies To", "value": this.pluckValues(hash["data"]["OBR.28"]) },
      { "field_name": "OBR-29", "field_description": "Parent", "value": this.pluckValues(hash["data"]["OBR.29"]) },
      { "field_name": "OBR-30", "field_description": "Transportation Mode", "value": this.pluckValues(hash["data"]["OBR.30"]) },
      { "field_name": "OBR-31", "field_description": "Reason for Study", "value": this.pluckValues(hash["data"]["OBR.31"]) },
      { "field_name": "OBR-32", "field_description": "Principal Result Interpreter", "value": this.pluckValues(hash["data"]["OBR.32"]) },
      { "field_name": "OBR-33", "field_description": "Assistant Result Interpreter", "value": this.pluckValues(hash["data"]["OBR.33"]) },
      { "field_name": "OBR-34", "field_description": "Technician", "value": this.pluckValues(hash["data"]["OBR.34"]) },
      { "field_name": "OBR-35", "field_description": "Transcriptionist", "value": this.pluckValues(hash["data"]["OBR.35"]) },
      { "field_name": "OBR-36", "field_description": "Scheduled Date/Time", "value": this.pluckValues(hash["data"]["OBR.36"]) },
      { "field_name": "OBR-37", "field_description": "Number of Sample Containers", "value": this.pluckValues(hash["data"]["OBR.37"]) },
      { "field_name": "OBR-38", "field_description": "Transport Logistics of Collected Sample", "value": this.pluckValues(hash["data"]["OBR.38"]) },
      { "field_name": "OBR-39", "field_description": "Collector’s Comment", "value": this.pluckValues(hash["data"]["OBR.39"]) },
      { "field_name": "OBR-40", "field_description": "Transport Arrangement Responsibility", "value": this.pluckValues(hash["data"]["OBR.40"]) },
      { "field_name": "OBR-41", "field_description": "Transport Arranged", "value": this.pluckValues(hash["data"]["OBR.41"]) },
      { "field_name": "OBR-42", "field_description": "Escort Required", "value": this.pluckValues(hash["data"]["OBR.42"]) },
      { "field_name": "OBR-43", "field_description": "Planned Patient Transport Comment", "value": this.pluckValues(hash["data"]["OBR.43"]) },
    ];
  }
}
