import React, { useState } from "react";
import "react-phone-number-input/style.css";
import { PatchParams, Referral } from "../../services/MediaPatient";
import moment from "moment";
import { activityFeed } from "../activity/ActivityFeed";

export interface PatientInformationParams {
  referral: Referral;
  updateReferral: (params: PatchParams) => void;
  isSaving: boolean;
  saveButtonText: string;
}

enum Tabs {
  details,
  activityFeed,
}

export const PatientInformation: React.FC<PatientInformationParams> = (params: PatientInformationParams) => {
  const [referral] = React.useState<Referral>(params.referral);

  const [firstName] = React.useState<string>(params.referral.patient.firstName);
  const [lastName] = React.useState<string>(params.referral.patient.lastName);
  const [email, setEmail] = React.useState<string>(params.referral.patient.email);
  const [address, setAddress] = React.useState<string>(params.referral.patient.address);
  const [city, setCity] = React.useState<string>(params.referral.patient.city);
  const [state, setState] = React.useState<string>(params.referral.patient.state);
  const [zipCode, setZipCode] = React.useState<string>(params.referral.patient.zipCode);
  const [medicareNumber, setMedicareNumber] = React.useState<string>(params.referral.patient.medicareNumber);
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.details);
  const [expanded, setExpanded] = useState(false);

  const handleTabClick = (tab: Tabs) => {
    setCurrentTab(tab);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const activeTabCss = "font-bold cursor-pointer inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
  const inactiveTabcss = "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";

  return (
    <div>
      <div className="flex justify-between items-center pb-3 p-4">
        <h2 className="text-lg font-bold leading-7 text-gray-900">Patient</h2>
        <button
          className="md:w-auto flex items-center py-2 px-4 rounded bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-medium" 
          onClick={() => params.updateReferral(
            {
              referralStatusId: null,
              userId: null,
              firstName: firstName,
              lastName: lastName,
              address: address,
              city: city,
              state: state,
              zipCode: zipCode,
              medicareNumber: medicareNumber,
              dateOfBirth: null,
              email: email
            }
          )}>
          <span className="inline-block mr-1 text-indigo-300">
            {
              params.isSaving && (
                <span className="inline-block h-4 w-4 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
              )
            }
          </span>
          <span>{params.saveButtonText}</span>
        </button>
      </div>

      <div className="">
        <div className="px-4 pb-1">
          <div className="text-center">
            <div
              className="h-32 w-32 bg-green-500 rounded-full border-1 border-white dark:border-gray-800 mx-auto my-4 flex items-center justify-center"
            >
              <span className="text-4xl text-gray-800 dark:text-white font-bold">
                {firstName.charAt(0)}{lastName.charAt(0)}
              </span>
            </div>

            <div className="pt-2">
              <h3 className="font-bold text-2xl mb-1">{firstName} {lastName}</h3>
                {
                  city && (
                    <div className="inline-flex text-gray-700 dark:text-gray-600 items-center">
                      <svg
                        className="h-5 w-5 text-gray-400 dark:text-gray-600 mr-1"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path
                          className=""
                          d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                        />
                      </svg>
                      {city}
                    </div>
                  )
                }
              </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center pl-4 pb-6 font-medium text-center text-gray-500  dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          <li className="me-2">
            <div
              className={`${
                currentTab === Tabs.details ? activeTabCss : inactiveTabcss
              }`}
              onClick={() => handleTabClick(Tabs.details)}
            >
              Details
            </div>
          </li>
          <li className="me-2">
            <div
              className={`${
                currentTab === Tabs.activityFeed ? activeTabCss : inactiveTabcss
              }`}
              onClick={() => handleTabClick(Tabs.activityFeed)}
            >
              {`Activity (${referral.patient.activityLogs?.length || 0})`}
            </div>
          </li>
        </ul>
      </div>

      {
        currentTab === Tabs.details && (
          <div className="p-4">
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Mobile Phone</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={referral.patient.mobilePhoneNumber}
                disabled={true}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Date Of Birth</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={moment(referral.patient.dateOfBirth).format("DD-MM-YYYY").toString()}
                disabled={true}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Email</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Address</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">City</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">State</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Zip code</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </label>
            <label className="block pb-3">
              <span className="block text-sm font-medium text-gray-700 pb-1">Medicare</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={medicareNumber}
                onChange={(e) => setMedicareNumber(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="block text-sm font-medium text-gray-700 pb-1">Created at</span>
              <input
                className="w-full border rounded p-1 px-3 text-gray-600"
                value={moment(referral.createdAt).local().format("h:mm:ss A - DD/MM/YYYY")}
                disabled={true}
              />
            </label>
          </div>
        )
      }

      {
        currentTab === Tabs.activityFeed && referral.patient.activityLogs && (
          activityFeed(referral.patient.activityLogs, expanded, toggleExpanded)
        )
      }
    </div>
  );
};

export default PatientInformation;


