import ReferralsList from "./components/referrals/ReferralsList";
import {
  createBrowserRouter,
} from "react-router-dom";

import LandingPage from "./components/LandingPage";
import PrivateRoute from "./components/authentication/PrivateRoute";

import ReferralShow from "./components/referrals/ReferralShow";
import ReferralUploadWrapper from "./components/referrals/ReferralUploadWrapper";
import React from "react";
import BookingPreference from "./components/referrals/registration/BookingPreference";
import SuccessfulRegistration from "./components/referrals/registration/SuccessfulRegistration";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/referrals",
    element: <PrivateRoute component={ReferralsList} />,
  },
  {
    path: "/:slug/referral_upload",
    element: <ReferralUploadWrapper />,
  },
  {
    path: "/:slug/referrals/:referralId/booking_preferences",
    element: <BookingPreference />,
  },
  {
    path: "/:slug/referrals/:referralId/success",
    element: <SuccessfulRegistration />,
  },
  {
    path: "/referrals/:referralId",
    element: <PrivateRoute component={ReferralShow} />,
  },
]);
