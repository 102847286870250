import SegmentBase from "./SegmentBase";

export default class MSHSegment extends SegmentBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(hash: any) {
    return [
      { "field_name": "MSH-1", "field_description": "Field Separator", "value": "MSH" },
      { "field_name": "MSH-2", "field_description": "Encoding Characters", "value": hash["data"]["MSH.2"] },
      { "field_name": "MSH-3", "field_description": "Sending Application", "value": hash["data"]["MSH.3"] },
      { "field_name": "MSH-4", "field_description": "Sending Facility", "value": hash["data"]["MSH.4"] },
      { "field_name": "MSH-5", "field_description": "Receiving Application", "value": hash["data"]["MSH.5"] },
      { "field_name": "MSH-6", "field_description": "Receiving Facility", "value": hash["data"]["MSH.6"] },
      { "field_name": "MSH-7", "field_description": "Date/Time of Message", "value": hash["data"]["MSH.7"] },
      { "field_name": "MSH-8", "field_description": "Security", "value": hash["data"]["MSH.8"] },
      { "field_name": "MSH-9", "field_description": "Message Type", "value": this.pluckValues(hash["data"]["MSH.9"]) },
      { "field_name": "MSH-10", "field_description": "Message Control ID", "value": hash["data"]["MSH.10"] },
      { "field_name": "MSH-11", "field_description": "Processing ID", "value": hash["data"]["MSH.11"] },
      { "field_name": "MSH-12", "field_description": "Version ID", "value": hash["data"]["MSH.12"] },
      { "field_name": "MSH-13", "field_description": "Sequence Number", "value": hash["data"]["MSH.13"] },
      { "field_name": "MSH-14", "field_description": "Continuation Pointer", "value": hash["data"]["MSH.14"] }
    ];
  }
}
