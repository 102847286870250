import hl7standard from "hl7-standard";
import MSHSegment from "./segments/MSHSegment";
import PIDSegment from "./segments/PIDSegment";
import PV1Segment from "./segments/PV1Segment";
import ORCSegment from "./segments/ORCSegment";
import OBRSegment from "./segments/OBRSegment";
import OBXSegment from "./segments/OBXSegment";

export default class Hl7Parser {
  hl7Message = null;

  constructor(hl7Message) {
    // We have to modified the hl7 message because MedicalObjects has invalid HL7 Message.
    // Theres does not start with MSH which makes it an invalid HL7 Message.
    this.hl7Message = hl7Message.substring(hl7Message.indexOf("MSH"));
  }

  async parse() {
    try {
      const mshSegment = new MSHSegment();
      const pidSegment = new PIDSegment();
      const pv1Segment = new PV1Segment();
      const orcSegment = new ORCSegment();
      const obrSegment = new OBRSegment();
      const obxSegment = new OBXSegment();

      let hl7 = new hl7standard(this.hl7Message);
      hl7.transform();

      const hash = [
        { segment: "PID", data: pidSegment.parse(hl7.getSegment("PID")) },
        { segment: "OBR", data: obrSegment.parse(hl7.getSegment("OBR")) },
        { segment: "MSH", data: mshSegment.parse(hl7.getSegment("MSH")) },
        { segment: "PV1", data: pv1Segment.parse(hl7.getSegment("PV1")) },
        { segment: "ORC", data: orcSegment.parse(hl7.getSegment("ORC")) },
        { segment: "OBX", data: obxSegment.parse(hl7.getSegment("OBX")) },
      ];

      return hash;
     } catch (e) {
       console.log("errror");
       console.error(e);
     }
  }

  serviceInfo(obrSegment) {
    return {
      universalServiceId: obrSegment.data["OBR.4"] ?? "",
      relevantClinicInfo: obrSegment.data["OBR.13"] ?? "",
    };
  }

  async patientDetails() {
    let hl7 = new hl7standard(this.hl7Message);
    hl7.transform();

    const patientHash = hl7.getSegment("PID");
    const serviceInfo = hl7.getSegment("OBR");
    const sentFrom = hl7.getSegment("MSH");

    const requestedServices = hl7.getSegments("OBR").map(obrSegment => this.serviceInfo(obrSegment));

    return {
      firstName: patientHash.data["PID.5"]?.["PID.5.2"] ?? "",
      lastName: patientHash.data["PID.5"]?.["PID.5.1"] ?? "",
      address: patientHash.data["PID.11"]?.["PID.11.1"] ?? "",
      city: patientHash.data["PID.11"]?.["PID.11.3"] ?? "",
      state: patientHash.data["PID.11"]?.["PID.11.4"] ?? "",
      zipCode: patientHash.data["PID.11"]?.["PID.11.5"] ?? "",
      mobilePhoneNumber: patientHash.data["PID.13"]?.[0]?.["PID.13.7"] ?? "",
      email: patientHash.data["PID.13"]?.[1]?.["PID.13.4"] ?? "",
      dateOfBirth: patientHash.data["PID.7"] ?? "",
      medicareNumber: `${patientHash.data["PID.3"]?.[1]?.["PID.3.1"] ?? ""}-${patientHash.data["PID.3"]?.[1]?.["PID.3.2"] ?? ""}`,
      facilityName: sentFrom.data["MSH.4"]?.["MSH.4.1"] ?? "",
      doctorFirstName: serviceInfo.data["OBR.16"]?.[0]?.["OBR.16.3"] ?? "",
      doctorLastName: serviceInfo.data["OBR.16"]?.[0]?.["OBR.16.2"] ?? "",
      providerNumber: serviceInfo.data["OBR.16"]?.[0]?.["OBR.16.1"] ?? "",
      observationDateTime: serviceInfo.data["OBR.7"] ?? "",
      requestedServices: requestedServices,
      sex: patientHash.data["PID.8"] ?? "",
    };
  }
}