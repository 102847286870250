import { currentUser } from "./BaseService";

interface ActivityLogsResponse {
  data: ActivityLogs | null,
  error: string | null;
}

interface ActivityLogs {
  activityLogs: ActivityLog[] | null,
  totalCount: number,
}

export interface ActivityLog {
    id: string,
    typeOf: string,
    reference: string,
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any; // Define the structure of metadata as a key-value pair
    };
    createdAt: string,
}

export default class ActivityLogsService  {
  static async index(
    token: string | null, 
    typeOf: string,
    reference?: string | null,
    itemsPerPage = 10, 
    itemOffset = 0,
  ): Promise<ActivityLogsResponse> {
    let apiUrl = `${process.env.REACT_APP_API_HOST_URL}/activity_logs?itemsPerPage=${itemsPerPage}&itemOffset=${itemOffset}`;

    if (typeOf) { apiUrl += `&typeOf=${typeOf}`;}
    if (reference) { apiUrl += `&reference=${reference}`;}

    const response = await fetch(
      apiUrl, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "Tenant-Slug": (await currentUser()).tenantSlug,
        },
        method: "GET",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const activityLogs: ActivityLogs = responseData.data;
      return { 
        data: { 
          activityLogs: activityLogs.activityLogs, 
          totalCount: activityLogs.totalCount 
        }, 
        error: null 
      };
    } else {
      return { data: null, error: "Fetching media patients failed" };
    }
  }
}