/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from "react";
import S3Service from "../../../services/aws/S3Service";
import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Hl7Parser from "../../../services/hl7/Hl7Parser";
import PrintHl7FileReferral from "./print/PrintHl7FileReferral";

export interface DisplayHl7Props {
  hl7S3Url: string | undefined;
  setHl7PatientDetails: React.Dispatch<React.SetStateAction<PatientDetails | null>>;
}

export interface PatientDetails {
  firstName: string | "";
  middleName: string | "";
  lastName: string | "";
  address: string | "";
  city: string | "";
  state: string | "";
  zipCode: string | "";
  email: string | "";
  mobilePhoneNumber: string | "";
  dateOfBirth: string | "";
  medicareNumber: string | "";
  facilityName: string | "";
  doctorFirstName: string | "";
  doctorLastName: string | "";
  providerNumber: string | "";
  observationDateTime: string | "";
  requestedServices: RequestedServices[] | [];
  sex: string | "";
}

export interface RequestedServices {
  universalServiceId: string | "";
  relevantClinicInfo: string | "";
}


enum Tabs {
  referral,
  hl7Details,
  hl7Raw
}

const DisplayHl7: FC<DisplayHl7Props> = ({ hl7S3Url, setHl7PatientDetails }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hl7Hash, setHl7Hash] = useState<object[] | null>(null);
  const [rawHl7Data, setRawHl7Data] = useState<string | "">("");
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.referral);
  const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
  
  useEffect(() => {
    const fetchHl7Data = async () => {
      setIsLoading(true);
        const response = await S3Service.getHl7Message(`${process.env.REACT_APP_S3_URL}/${hl7S3Url}`);
        if (response.hl7Message) {
          setRawHl7Data(response.hl7Message);
          const parser = await new Hl7Parser(response.hl7Message);
          const parsedData = await parser.parse();
          console.log("parsedData", parsedData);
          setHl7Hash(parsedData);

          const patientHash = await parser.patientDetails();
          console.log("parsedData", parsedData);
          setHl7PatientDetails(patientHash);
          setPatientDetails(patientHash);

          setIsLoading(false);
        }
    
    };

    fetchHl7Data();
  }, [hl7S3Url]);

  const handleTabClick = (tab: Tabs) => {
    setCurrentTab(tab);
  };

  const activeTabCss = "font-bold cursor-pointer inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
  const inactiveTabcss = "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";

  return (
    <div className="">
      {
        isLoading && (
          <div className="flex justify-center items-center h-full">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
          </div>
        )
      }

      {
        !isLoading && (
          <div>
             <div className="pl-4 text-sm font-medium text-center text-gray-500  dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                  <li className="me-2">
                    <div
                      className={`${
                        currentTab === Tabs.referral ? activeTabCss : inactiveTabcss
                      }`}
                      onClick={() => handleTabClick(Tabs.referral)}
                    >
                      Referral
                    </div>
                  </li>
                  <li className="me-2">
                    <div
                      className={`${
                        currentTab === Tabs.hl7Details ? activeTabCss : inactiveTabcss
                      }`}
                      onClick={() => handleTabClick(Tabs.hl7Details)}
                    >
                      HL7 Details
                    </div>
                  </li>
                  <li className="me-2">
                    <div
                      className={`${
                        currentTab === Tabs.hl7Raw ? activeTabCss : inactiveTabcss
                      }`}
                      onClick={() => handleTabClick(Tabs.hl7Raw)}
                    >
                      HL7 Raw
                    </div>
                  </li>
                </ul>
              </div>
            <div className="mb-6 bg-white rounded-lg relative">
              {
                currentTab === Tabs.referral && 
                patientDetails && (
                  <PrintHl7FileReferral patientDetails={patientDetails} />
                )
              }

              {
                currentTab === Tabs.hl7Raw && (
                  <div className="pt-6">
                    <textarea
                      className="border border-gray-300 rounded-md p-2 mb-6"
                      style={{ height: "6em", width: "100%", overflowY: "scroll" }}
                      value={rawHl7Data}
                      readOnly
                    />
                  </div>
                )
              }
              {
                currentTab === Tabs.hl7Details && (
                  <div className="pt-6">
                    <table className="w-full">
                      <thead>
                        <tr className="text-xs text-gray-500 text-left">
                          <th className="p-3 pr-5 font-medium">Name</th>
                          <th className="p-3 pr-7 font-medium">Description</th>
                          <th className="p-3 font-medium">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hl7Hash?.map((section: any, index: any) => (
                          section.data.filter((row: any) => row.value !== "").map((row: any, rowIndex: any) => (
                            <tr
                              key={index}
                              className={
                                rowIndex % 2 === 0
                                  ? "text-xs bg-gray-50 h-16"
                                  : "text-xs h-16"
                              }
                            >
                              <td className="cursor-pointer p-2 border border-gray-100">{row.field_name}</td>
                              <td className="cursor-pointer p-2 border border-gray-100">{row.field_description}</td>
                              <td className="cursor-pointer p-2 max-w-xs overflow border border-gray-100" style={{wordWrap: "break-word"}}>
                                {typeof row.value === "string" ? row.value : JSON.stringify(row.value)}
                              </td>
                            </tr>
                          ))
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) 
              }
            </div>
          </div>
        )
      }
    </div>
  );
};

export default DisplayHl7;
