import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import printerIcon from "../../../../assets/icons/printer.svg";
import ImageDisplay from "../../../ImageDisplay";

type PrintThisHtmlProps = {
  children?: React.ReactNode;
};

interface PrintReferralImageProps {
  imageS3Url: string;
}

const PrintThisHtml = React.forwardRef<HTMLDivElement, PrintThisHtmlProps>((props, ref) => (
  <div ref={ref} className="m-4">
    {props.children}
  </div>
));

PrintThisHtml.displayName = "PrintThisHtml";

const PrintReferralImage: React.FC<PrintReferralImageProps> = ({ imageS3Url }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPrinting, setIsPrinting] = React.useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    }
  });

  return (
    <div className="relative">
      <div 
        className="absolute border rounded-full bg-white font-medium border-black flex items-center py-2 px-5 m-3 z-10 cursor-pointer hover:bg-purple-100 shadow" 
        style={{ right: "20px" }}
        onClick={handlePrint}
      >
        <img src={printerIcon} alt="Printer Icon" className="w-6 h-6 mr-1" />
        Print
      </div>

      <PrintThisHtml ref={componentRef}>
        <div>
          <ImageDisplay 
            imageKey={imageS3Url} 
            className="rounded-lg w-full cursor-zoom" 
          />
        </div>
      </PrintThisHtml>
    </div>
  );
};

export default PrintReferralImage;
