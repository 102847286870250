import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Location } from "../models/Location";

export const locationService = createApi({
  reducerPath: "locationService",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_HOST_URL }),
  endpoints: (builder) => ({
    getLocations: builder.query<
      LocationResponse,
      { slug: string; lat?: string; lng?: string; radiusInMeters?: number }
    >({
      query: ({ slug, lat, lng, radiusInMeters }) => {
        const params = new URLSearchParams();

        if (lat) {
          params.append("latitude", lat);
        }
        if (lng) {
          params.append("longitude", lng);
        }
        if (radiusInMeters) {
          params.append("radiusInMeters", radiusInMeters.toString());
        }

        return {
          url: `/public/locations?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Tenant-Slug": slug,
          },
        };
      },
    }),
  }),
});

export const { useGetLocationsQuery } = locationService;

export interface LocationResponse {
  data: {
    locations: Location[] | null;
    totalCount: number;
  };
}
