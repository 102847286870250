import SegmentBase from "./SegmentBase";

export default class OBXSegment extends SegmentBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(hash: any) {
    return [
      { "field_name": "OBX-1", "field_description": "Set ID - OBX", "value": this.pluckValues(hash["data"]["OBX.1"]) },
      { "field_name": "OBX-2", "field_description": "Value Type", "value": this.pluckValues(hash["data"]["OBX.2"]) },
      { "field_name": "OBX-3", "field_description": "Observation Identifier", "value": this.pluckValues(hash["data"]["OBX.3"]) },
      { "field_name": "OBX-4", "field_description": "Observation Sub-Id", "value": this.pluckValues(hash["data"]["OBX.4"]) },
      { "field_name": "OBX-5", "field_description": "Observation Value", "value": this.pluckValues(hash["data"]["OBX.5"]) },
      { "field_name": "OBX-6", "field_description": "Units", "value": this.pluckValues(hash["data"]["OBX.6"]) },
      { "field_name": "OBX-7", "field_description": "Reference Range", "value": this.pluckValues(hash["data"]["OBX.7"]) },
      { "field_name": "OBX-8", "field_description": "Abnormal Flags", "value": this.pluckValues(hash["data"]["OBX.8"]) },
      { "field_name": "OBX-9", "field_description": "Probability", "value": this.pluckValues(hash["data"]["OBX.9"]) },
      { "field_name": "OBX-10", "field_description": "Nature of Abnormal Test", "value": this.pluckValues(hash["data"]["OBX.10"]) },
      { "field_name": "OBX-11", "field_description": "Observ Result Status", "value": this.pluckValues(hash["data"]["OBX.11"]) },
      { "field_name": "OBX-12", "field_description": "Data Last Obs Normal Values", "value": this.pluckValues(hash["data"]["OBX.12"]) },
      { "field_name": "OBX-13", "field_description": "User-Defined Access Checks", "value": this.pluckValues(hash["data"]["OBX.13"]) },
      { "field_name": "OBX-14", "field_description": "Date/Time of the Observation", "value": this.pluckValues(hash["data"]["OBX.14"]) },
      { "field_name": "OBX-15", "field_description": "Producer’s Id", "value": this.pluckValues(hash["data"]["OBX.15"]) },
      { "field_name": "OBX-16", "field_description": "Responsible Observer", "value": this.pluckValues(hash["data"]["OBX.16"]) },
      { "field_name": "OBX-17", "field_description": "Observation Method", "value": this.pluckValues(hash["data"]["OBX.17"]) },
    ];
  }
}
