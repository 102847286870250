import React, { useRef, useEffect } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { OpenCageLocation } from "../referrals/registration/SelectLocation";
import { MarkerF } from "@react-google-maps/api";

const mapContainerStyle = {
  height: "300px",
  width: "100%",
};

export interface Params {
  centerLocation?: OpenCageLocation | null;
  locations: OpenCageLocation[];
  zoom?: number | null;
  onMarkerClick?: (location: OpenCageLocation) => void;
  mapContainerStyle?: object | null;
}

const LocationMap: React.FC<Params> = (props: Params) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
  const { isLoaded } = useLoadScript({ googleMapsApiKey: apiKey });
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (mapRef.current && props.locations.length > 0 && !props.zoom) {
      const bounds = new window.google.maps.LatLngBounds();
      props.locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(
            location.coordinates.lat,
            location.coordinates.lng,
          ),
        );
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [props.locations, props.zoom]);

  const handleOnLoad = (map: google.maps.Map) => {
    mapRef.current = map;

    if (!props.zoom && props.locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      props.locations.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(
            location.coordinates.lat,
            location.coordinates.lng,
          ),
        );
      });
      map.fitBounds(bounds);
    }
  };

  return (
    <div>
      {!isLoaded && <div>Loading...</div>}

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={props.mapContainerStyle || mapContainerStyle}
          zoom={props.zoom || undefined}
          center={props.centerLocation?.coordinates} // Fallback to a default center
          onLoad={handleOnLoad}
        >
          {props.locations.map((location, index) => (
            <MarkerF
              key={index}
              position={location.coordinates}
              title={location.address}
              onClick={() => props.onMarkerClick?.(location)}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default LocationMap;
