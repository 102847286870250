import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserAPI from "../../services/User";
import store from "../../redux/store";
import { Organisation } from "../referrals/ReferralUploadWrapper";
import { useGetOrganisationBySlugQuery } from "../../services/OrganisationService";
import { useGetLocationsQuery } from "../../services/LocationService";
import { CurrentUser } from "../../services/User";
import { Location } from "../../models/Location";

const TopNavBar: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { user, getIdTokenClaims } = useAuth0();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

  store.subscribe(() => {
    setCurrentLocation(store.getState().currentLocation);
  });

  const tenantSlug = currentUser?.tenantSlug;

  const {
    data: organisationData,
    error: organisationError,
    isLoading: organisationLoading,
  } = useGetOrganisationBySlugQuery(tenantSlug || "", {
    skip: !tenantSlug,
  });

  const {
    data: locationData,
    error: locationError,
    isLoading: locationsLoading,
  } = useGetLocationsQuery(
    { slug: organisation?.slug || "" },
    {
      skip: !organisation?.slug,
    },
  );

  useEffect(() => {
    if (currentUser === null && user !== undefined) {
      fetchCurrentUser();
    }
  }, [currentUser, user]);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(target) &&
      !target.closest(".status-dropdown")
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const fetchCurrentUser = async () => {
    const token = await getAccessToken();
    if (user?.sub) {
      const response = await UserAPI.currentUser(user?.sub, token);

      if (response.data) {
        const user = response.data as CurrentUser;
        setCurrentUser(user);
        store.dispatch({ type: "currentUserState", payload: user });
      } else {
        console.error("Fetch current user failed:", response.error);
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  useEffect(() => {
    if (organisationError) {
      console.error("Failed to fetch organisation", organisationError);
      return;
    }

    if (organisationLoading) {
      return;
    }

    if (organisationData) {
      store.dispatch({
        type: "organisationState",
        payload: organisationData.data,
      });
      setOrganisation(organisationData.data);
    }
  }, [organisationData, organisationError, organisationLoading]);

  useEffect(() => {
    if (locationError) {
      console.error("Failed to fetch locations", locationError);
    }
  }, [locationError]);

  useEffect(() => {
    const storedLocationId = localStorage.getItem("currentLocationId");
    const locations = locationData?.data;

    if (storedLocationId && locations) {
      const location = locations.locations?.find(
        (loc) => loc.id === storedLocationId,
      );
      store.dispatch({ type: "currentLocationState", payload: location });
    } else if (locations) {
      const defaultLocation = locations.locations?.find((loc) => loc.isDefault);
      store.dispatch({
        type: "currentLocationState",
        payload: defaultLocation,
      });
    }
  }, [locationData]);

  if (!organisation) {
    return null;
  }

  const locations = locationData?.data;

  const storeCurrentLocation = (locationId: string) => {
    const location = locations?.locations?.find(
      (location) => location.id === locationId,
    );
    if (location) {
      store.dispatch({ type: "currentLocationState", payload: location });
      localStorage.setItem("currentLocationId", locationId);
    }
  };

  return (
    <section
      className="py-3 px-6 bg-white status-dropdown"
      style={{ borderBottom: "1px solid #e4e4e4" }}
    >
      <nav>
        <div className="relative flex justify-between items-center">
          <div>
            {!locationsLoading && (
              <div className="relative h-10 w-72 min-w-[200px]">
                <select
                  id="currentLocation"
                  className="cursor-pointer peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  value={currentLocation?.id}
                  onChange={(e) => storeCurrentLocation(e.target.value)}
                >
                  {locations?.locations?.map((location) => (
                    <option
                      className="cursor-pointer"
                      key={location.id}
                      value={location.id}
                    >
                      {location.name}
                    </option>
                  ))}
                </select>
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Location
                </label>
              </div>
            )}
          </div>

          <div className="flex items-center">
            <div className="mr-3">
              <p className="text-sm">{currentUser?.email}</p>
            </div>

            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center"
            >
              <div className="mr-2">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={currentUser?.avatarUrl}
                  alt=""
                />
              </div>
              <span>
                <svg
                  className="text-gray-400"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.08335 0.666657C8.75002 0.333323 8.25002 0.333323 7.91669 0.666657L5.00002 3.58332L2.08335 0.666657C1.75002 0.333323 1.25002 0.333323 0.916687 0.666657C0.583354 0.99999 0.583354 1.49999 0.916687 1.83332L4.41669 5.33332C4.58335 5.49999 4.75002 5.58332 5.00002 5.58332C5.25002 5.58332 5.41669 5.49999 5.58335 5.33332L9.08335 1.83332C9.41669 1.49999 9.41669 0.99999 9.08335 0.666657Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </button>
            {isDropdownOpen && (
              <div ref={dropdownRef}>
                <Dropdown organisation={organisation} />
              </div>
            )}
          </div>
        </div>
      </nav>
    </section>
  );
};

export default TopNavBar;

interface DropdownProps {
  organisation: Organisation | null;
}

const Dropdown: React.FC<DropdownProps> = ({ organisation }) => {
  const { logout } = useAuth0();

  return (
    <div
      className="absolute right-0 top-10 mt-2 w-52 bg-white border border-gray-200 rounded shadow-lg z-10"
      onClick={(e) => e.stopPropagation()} // Prevent clicks inside the dropdown from closing it
    >
      <div className="p-4 text-left border-b border-gray-200 pb-2">
        <p className="text-xs font-medium">Organisation:</p>
        <p className="font-medium">{organisation?.name}</p>
      </div>

      <p
        className="p-4 text-black cursor-pointer hover:bg-gray-100"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Log out
      </p>
    </div>
  );
};
