import { Organisation } from "../components/referrals/ReferralUploadWrapper";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface OrganisationResponse {
  data: Organisation | null;
  error: string | null;
}

export const organisationService = createApi({
  reducerPath: "organisationService",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_HOST_URL }),
  endpoints: (builder) => ({
    getOrganisationBySlug: builder.query<OrganisationResponse, string>({
      query: (slug) => ({
        url: `/public/organisations/${slug}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": slug,
        },
      }),
    }),
  }),
});

export const { useGetOrganisationBySlugQuery } = organisationService;