import React, { useState, useEffect } from "react";
import CameraIcon from "../../assets/icons/camera.svg";
import ReactFileReader from "react-file-reader";
import { awsFileUpload } from "../../services/AwsFileUpload";
import MediaPatientAPI, { ReferralOrigins } from "../../services/MediaPatient";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import Hotjar from "@hotjar/browser";
import { Organisation } from "./ReferralUploadWrapper";
import { useNavigate, useParams } from "react-router-dom";
import store from "../../redux/store";
import { Location } from "../../models/Location";
import ClientHeader from "./registration/ClientHeader";
import TopBar from "./registration/TopBar";

export interface Params {
  organisation: Organisation;
  location: Location;
  setCurrentLocation: (location: Location | null) => void;
}

const UploadReferral: React.FC<Params> = (clinicParams: Params) => {
  const { slug } = useParams<{ slug: string }>();

  const navigate = useNavigate();
  const referralFromStore = store.getState().referral;
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>(
    referralFromStore?.patient.mobilePhoneNumber || "",
  );
  const [origin, setOrigin] = useState<ReferralOrigins>(
    ReferralOrigins.patient_upload_image_client,
  );
  const [firstName, setFirstName] = useState<string>(
    referralFromStore?.patient.firstName || "",
  );
  const [lastName, setLastName] = useState<string>(
    referralFromStore?.patient.lastName || "",
  );
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    referralFromStore?.patient.dateOfBirth || "",
  );

  const [showMobilePhoneNumberError, setShowMobilePhoneNumberError] =
    useState<boolean>(false);
  const [showFirstNameError, setShowFirstNameError] = useState<boolean>(false);
  const [showLastNameError, setShowLastNameError] = useState<boolean>(false);
  const [showDateOfBirthError, setShowDateOfBirthError] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const initHotJar = () => {
      console.log("init hotjar");
      const siteId = 4988497;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    };

    initHotJar();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("mobile_phone") !== null) {
      const mobilePhoneParam = `+${urlParams.get("mobile_phone")}`;
      setMobilePhoneNumber(mobilePhoneParam || "");
    }

    if (urlParams.get("origin") !== null) {
      const originParam = `${urlParams.get("origin")}`;

      if (originParam === ReferralOrigins.company_website) {
        setOrigin(ReferralOrigins.company_website);
      }
      if (originParam === ReferralOrigins.patient_upload_image_client) {
        setOrigin(ReferralOrigins.patient_upload_image_client);
      }
    }
  });

  const handleFiles = async (files: FileList) => {
    if (handleInvalidForm()) {
      return;
    }
    setLoading(true);

    let file = files[0];
    try {
      file = await convertImageToJPG(file);
    } catch (error) {
      alert(`Failed to convert image to JPG ${error}`);
      setLoading(false);
      return;
    }

    const params = {
      file: file,
      slug: clinicParams.organisation.slug,
    };

    const result = await awsFileUpload(params);

    if (result.filePath) {
      const createParams = {
        url: result.filePath,
        recipientEmail: clinicParams.location.email,
        mobilePhoneNumber: mobilePhoneNumber.replace(/\s+/g, ""),
        locationId: clinicParams.location.id,
        firstName,
        lastName,
        dateOfBirth,
        origin,
      };

      const response = await MediaPatientAPI.create(
        createParams,
        clinicParams.organisation.slug,
      );

      if (response.data != null) {
        store.dispatch({ type: "referralState", payload: response.data });
        if (slug === "mermaid_beach_radiology") {
          // TODO: Create feature flag for this
          navigate(`/${slug}/referrals/${response.data.id}/success`);
        } else {
          navigate(
            `/${clinicParams.organisation.slug}/referrals/${response.data.id}/booking_preferences`,
            {
              state: {
                organisation: clinicParams.organisation,
                currentLocation: store.getState().currentLocation,
              },
            },
          );
        }
      } else {
        alert("Upload failed");
      }
    } else {
      alert("Upload failed");
    }

    setLoading(false);
  };

  const handleMobilePhoneNumberChange = (value?: string) => {
    setShowMobilePhoneNumberError(false);
    setMobilePhoneNumber(value?.toString() || "");
  };

  const handleInvalidForm = (): boolean => {
    let invalid = false;
    if (mobilePhoneNumber === "") {
      setShowMobilePhoneNumberError(true);
      invalid = true;
    }
    if (firstName === "") {
      setShowFirstNameError(true);
      invalid = true;
    }
    if (lastName === "") {
      setShowLastNameError(true);
      invalid = true;
    }
    if (dateOfBirth === "") {
      setShowDateOfBirthError(true);
      invalid = true;
    }

    return invalid;
  };

  const isFieldsBlank = (): boolean => {
    return (
      mobilePhoneNumber === "" ||
      firstName === "" ||
      lastName === "" ||
      dateOfBirth === ""
    );
  };

  const handleDateChange = (value: string) => {
    const inputDate = value;
    const isValidDate = moment(inputDate, "YYYY-MM-DD", true).isValid();
    const isFutureDate = moment(inputDate).isAfter(moment());

    if (!isValidDate || isFutureDate) {
      setShowDateOfBirthError(true);
    } else {
      setShowDateOfBirthError(false);
      setDateOfBirth(inputDate);
    }
  };

  return (
    <div className="content-center">
      <div className="mx-auto max-w-[600px]">
        {
          <div>
            <TopBar
              heading={"Upload Referral"}
              redirectBack={() => {
                store.dispatch({ type: "currentLocationState", payload: null });
                clinicParams.setCurrentLocation(null);
              }}
            />

            <ClientHeader
              organisation={clinicParams.organisation}
              currentLocation={store.getState().currentLocation}
            />
          </div>
        }

        {!loading && (
          <div>
            <div className="relative flex flex-col justify-center px-3 pt-4 rounded-xl">
              <label className="block pb-3">
                <span className="block font-medium text-gray-700">
                  First name
                </span>
                <input
                  className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number bg-white"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {showFirstNameError && (
                  <div>
                    <p className="text-sm text-red-600 pb-1">
                      First name required.
                    </p>
                  </div>
                )}
              </label>

              <label className="block pb-3">
                <span className="block font-medium text-gray-700">
                  Last name
                </span>
                <input
                  className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number bg-white"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {showLastNameError && (
                  <div>
                    <p className="text-sm text-red-600 pb-1">
                      Last name required.
                    </p>
                  </div>
                )}
              </label>

              <label className="block pb-3">
                <span className="block font-medium text-gray-700">
                  Date of birth
                </span>
                <input
                  className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number bg-white"
                  value={dateOfBirth}
                  onChange={(e) => handleDateChange(e.target.value)}
                  type="date"
                  id="birthday"
                  name="birthday"
                />
                {showDateOfBirthError && (
                  <div>
                    <p className="text-sm text-red-600 pb-1">
                      DOB required or wrong format.
                    </p>
                  </div>
                )}
              </label>

              <label className="block pb-4">
                <span className="block font-medium text-gray-700">
                  Mobile phone number
                </span>
                <PhoneInput
                  defaultCountry="AU"
                  country="AU"
                  value={mobilePhoneNumber}
                  withCountryCallingCode={true}
                  onChange={handleMobilePhoneNumberChange}
                  className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number bg-white"
                  placeholder="eg 0411 222 333"
                />
                {showMobilePhoneNumberError && (
                  <div>
                    <p className="text-sm text-red-600 pb-1">
                      Mobile phone number required.
                    </p>
                  </div>
                )}
              </label>

              {isFieldsBlank() && (
                <div onClick={() => handleInvalidForm()}>
                  <div className="flex justify-center bg-gray-100 flex-col p-4">
                    <p className="font-bold pb-8 text-gray-800 text-center">
                      Please take a photo of your referral
                    </p>
                    <div className="flex animate-bounce items-center justify-center w-24 h-24 mx-auto bg-indigo-700 rounded-full">
                      <img
                        src={CameraIcon}
                        alt="Camera Icon"
                        className="w-14 h-14"
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {!isFieldsBlank() && (
                <div>
                  <ReactFileReader
                    handleFiles={(file: FileList) => handleFiles(file)}
                    multipleFiles={false}
                    base64={false}
                  >
                    <div className="flex justify-center bg-gray-100 flex-col p-4">
                      <p className="font-bold pb-8 text-gray-800 text-center">
                        Please take a photo of your referral
                      </p>
                      <div className="flex animate-bounce items-center justify-center w-24 h-24 mx-auto bg-indigo-700 rounded-full">
                        <img
                          src={CameraIcon}
                          alt="Camera Icon"
                          className="w-14 h-14"
                          style={{ color: "white" }}
                        />
                      </div>
                    </div>
                  </ReactFileReader>
                </div>
              )}
            </div>
          </div>
        )}

        {loading && (
          <div className="relative">
            <div className="px-6 flex justify-center p-5">
              <div
                className="mt-8 h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
                style={{ color: "#21ad21" }}
              />
            </div>
            <p className="flex justify-center text-2xl font-bold pb-4 text-gray-800">
              Uploading your referral
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadReferral;

const convertImageToJPG = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const jpgFile = new File(
                [blob],
                file.name.replace(/\.[^/.]+$/, "") + ".jpg",
                {
                  type: "image/jpeg",
                },
              );
              resolve(jpgFile);
            } else {
              reject(new Error("Conversion to JPG failed"));
            }
          },
          "image/jpeg",
          0.95,
        );
      };
      img.onerror = () => {
        reject(new Error("Image load failed"));
      };
      if (event.target) {
        img.src = event.target.result as string;
      }
    };
    reader.onerror = () => {
      reject(new Error("File read failed"));
    };
    reader.readAsDataURL(file);
  });
};
