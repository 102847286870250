import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { routes } from "./Routes";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH_0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH_0_CLIENT_ID}`}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH_0_REDIRECT_URI
    }}
  >
    <Provider store={store}>
      <React.StrictMode>
        <RouterProvider router={routes} />
      </React.StrictMode>
    </Provider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
