import React, { useEffect, useRef, useState } from "react";
import ImageDisplay from "../../ImageDisplay";
import { Organisation } from "../ReferralUploadWrapper";
import { Location, toAddress } from "../../../models/Location";

export interface Params {
  organisation: Organisation;
  currentLocation?: Location | null;
}

const ClientHeader: React.FC<Params> = (props: Params) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popUpMessage, setPopUpMessage] = useState<string>("");
  const popupRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(true);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popupRef.current &&
      !(popupRef.current as HTMLDivElement).contains(event.target as Node)
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const website = props.currentLocation?.website || props.organisation.website;
  const email = props.currentLocation?.email || props.organisation.email;
  const loandline =
    props.currentLocation?.landline || props.organisation.landline;

  const address = () => {
    if (props.currentLocation) {
      return toAddress(props.currentLocation);
    } else {
      return props.organisation.address;
    }
  };

  return (
    <div className="relative flex mx-4 pt-4 pb-4">
      <div className="w-20 h-20 border border-gray-100">
        {props.organisation.logoUrl && (
          <ImageDisplay
            imageKey={props.organisation.logoUrl}
            className="w-20 h-20"
          />
        )}
      </div>

      <div className="pl-2" style={{ width: "75%" }}>
        <p
          className="text-xl font-bold pb-1"
          style={{
            left: "100px",
            bottom: "0px",
            color:
              props.organisation.metadata.profileHeaderFontColor || "black",
          }}
        >
          {props.organisation.name}
        </p>

        <div className="pb-1">
          <button
            className="pr-2 "
            onClick={() => window.open(website, "_blank")}
          >
            Website
          </button>
          <button
            className="pr-2 "
            onClick={() => {
              setShowPopup(true);
              setPopUpMessage(email);
            }}
          >
            Email
          </button>
          <button
            className=""
            onClick={() => {
              setShowPopup(true);
              setPopUpMessage(loandline);
            }}
          >
            Phone
          </button>
        </div>

        {props.currentLocation && (
          <div className="flex h-4 text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>

            <div
              className={`ml-1 h-5 cursor-pointer w-60 text-sm ${isTruncated ? "truncate" : ""}`}
              onClick={() => setIsTruncated(!isTruncated)}
            >
              {address()}
            </div>
          </div>
        )}
      </div>

      {showPopup && (
        <div
          ref={popupRef}
          className="absolute mt-2 w-64 p-4 bg-white border border-gray-300 shadow-lg z-50 rounded-lg ml-2"
          style={{ left: "0" }}
        >
          <button
            className="absolute top-1 right-2 text-gray-600 text-xl"
            onClick={() => setShowPopup(false)}
          >
            &times;
          </button>
          <p className="text-sm text-gray-700 pt-2">{popUpMessage}</p>
        </div>
      )}
    </div>
  );
};

export default ClientHeader;
