import s3 from "./AwsConfig";

interface DataResponse {
  hl7Message: string | null
  errors?: string[] | null
}

export default class S3Service {
  static async getHl7Message(hl7S3Url: string): Promise<DataResponse> {
    const params = {
      s3Hl7FileUrl: hl7S3Url
    };

    const response = await fetch(
      "https://0ntpvo4p7f.execute-api.ap-southeast-2.amazonaws.com/default/s3UrlToHl7Message", {
        headers: {
          "Content-Type": "text/plain",
        },
        method: "POST",
        body: JSON.stringify(params),
      }
    );

    if (response.ok) {
      const responseData = await response.text();
      return { hl7Message: responseData, errors: null };
    } else {
      return { hl7Message: "", errors: ["Failed to fetch HL7 message"]  };
    }
  }

  static getPresignedUrl = async (key: string, expires?: number | 15 ) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
      Expires: expires,
    };
  
    try {
      const url = await s3.getSignedUrlPromise("getObject", params);
      return url;
    } catch (error) {
      console.error("Error generating pre-signed URL", error);
      return null;
    }
  };
}
