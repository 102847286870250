import React from "react";

interface SearchFormProps {
  searchQuery: string | undefined;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
  requestSearch: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ searchQuery, setSearchQuery, requestSearch }) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      requestSearch();
    }
  };

  return(
    <div className="flex">
      <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
        Search
      </label>
      <div className="relative w-full">
        {
          searchQuery && searchQuery.length > 0 && 
          <p className="absolute text-xs text-yellow-600 right-0" style={{ top: "-20px" }}>
            Press <span className="font-bold">Enter</span> to search
          </p>
        }
        <div className="relative group">
          <input
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            onKeyDown={handleKeyDown}
            className="block p-2.5 z-20 text-sm bg-white rounded-lg border border-gray-400 w-60" 
            placeholder="Search" 
            required
          />
          <div className="absolute top-full mt-1 left-0 w-60 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 z-10">
            Search by patient first name, last name, or phone number.
          </div>
        </div>
        <button 
          type="submit" 
          onClick={() => requestSearch()} 
          className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-gray-400 rounded-r-lg"
        >
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
          <span className="sr-only">Search</span>
        </button>
      </div>
    </div>
  );
};

export default SearchForm;
