import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingPreferenceService from "../../../services/BookingPreferenceService";
import { useGetOrganisationBySlugQuery } from "../../../services/OrganisationService";
import { Location } from "../../../models/Location";
import ClientHeader from "./ClientHeader";
import TopBar from "./TopBar";
import { useGetLocationsQuery } from "../../../services/LocationService";

interface Params {
  currentLocation?: Location | null;
}

enum PreferredTime {
  EarlyMorning = "Early Morning",
  LateMorning = "Late Morning",
  EarlyAfternoon = "Early Afternoon",
  LateAfternoon = "Late Afternoon",
}

const BookingPreference: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { slug, referralId } = useParams<{
    slug: string;
    referralId: string;
  }>();

  const [preferredDate, setPreferredDate] = React.useState<string>("");
  const [preferredTime, setPreferredTime] = React.useState<PreferredTime | "">(
    "",
  );
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as Params | null;

  const { data: locationsData, isLoading: locationLoading } =
    useGetLocationsQuery(
      { slug: slug ?? "" },
      {
        skip: !!state?.currentLocation && !slug,
      },
    );
  const currentLocation =
    state?.currentLocation ||
    locationsData?.data.locations?.find(
      (location) => location.slug === useLocationSlug(),
    ) ||
    null;

  const { data: organisationData, isLoading: organisationLoading } =
    useGetOrganisationBySlugQuery(slug ?? "", { skip: !slug });
  const currentOrganisation = organisationData?.data || null;

  const loading = locationLoading || organisationLoading;

  const handleSubmit = async () => {
    if (!preferredDate || !preferredTime) {
      alert("Please fill in the date and time or press the 'Skip' button.");
      return;
    }

    if (!slug || !referralId) {
      return;
    }

    const response = await BookingPreferenceService.create({
      tenantId: slug,
      referralId,
      date: preferredDate,
      time: preferredTime,
    });

    if (response.data) {
      navigate(`/${slug}/referrals/${referralId}/success`);
    } else {
      alert("Error submitting booking preference. Please try again.");
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    const today = new Date();
    const selectedDate = new Date(inputDate);

    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      alert("The preferred date must be today or in the future.");
    } else {
      setPreferredDate(inputDate);
    }
  };

  return (
    <div className="content-center">
      {!!currentOrganisation && !!currentLocation && (
        <div className="mx-auto max-w-[600px]">
          {
            <div>
              <TopBar
                heading={"Preferred Time"}
                redirectBack={() =>
                  navigate(
                    `/${slug}/referral_upload?locSlug=${currentLocation?.slug}`,
                  )
                }
              />
              <ClientHeader
                organisation={currentOrganisation}
                currentLocation={currentLocation}
              />
            </div>
          }
          <div className="px-3 pt-4">
            <p className="pb-6">
              Please choose a preferred date and time for your procedure.
            </p>

            <label className="block pb-3">
              <span className="block text-sm">Preferred Date</span>
              <input
                className="w-full border rounded p-3 text-gray-600 text-lg input-phone-number bg-white"
                value={preferredDate}
                onChange={(e) => {
                  handleDateChange(e);
                }}
                type="date"
                id="preferred_date"
                name="preferred_date"
              />
            </label>

            <label className="block pb-4">
              <span className="block text-sm">Preferred Time</span>
              <select
                className="w-full border rounded p-3 text-gray-600 text-lg input-phone-number bg-white"
                value={preferredTime}
                onChange={(e) =>
                  setPreferredTime(e.target.value as PreferredTime)
                }
                id="preferred_time"
                name="preferred_time"
              >
                <option value="">Select a preferred time</option>
                {Object.values(PreferredTime).map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </label>

            <div className="flex">
              <button
                className="block w-full font-bold py-3 px-4 border border-gray rounded mr-2"
                onClick={() =>
                  navigate(`/${slug}/referrals/${referralId}/success`)
                }
              >
                Skip
              </button>
              <button
                className="block w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="flex justify-center pt-8">
          <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      )}
    </div>
  );
};

export default BookingPreference;

const useLocationSlug = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get("locSlug") || null;
};
