import React, { useState } from "react";
import headerBackground from "../assets/images/header-background.png";
import brainAi from "../assets/images/brain-ai.png";
import paymentsImg from "../assets/images/payments.png";
import headerRightImage from "../assets/images/header-right-image.png";
import captureSvg from "../assets/images/landing_page/Capture.svg";
import collectionSvg from "../assets/images/landing_page/Collection.svg";
import processingSvg from "../assets/images/landing_page/Processing.svg";
import aboutUsImg from "../assets/images/landing_page/about-us.png";
import yourPartners from "../assets/images/landing_page/your-partners.png";
import patientIpadImg from "../assets/images/landing_page/patient-doctor-ipad.png";
import paperlessSvg from "../assets/images/landing_page/paperless-solution.svg";
import dataCollectionSuite from "../assets/images/landing_page/data-collection.svg";
import reportingImg from "../assets/images/landing_page/reporting.png";
import phoneIcon from "../assets/icons/phone-call.svg";
import internetIcon from "../assets/icons/internet.svg";
import doctorIcon from "../assets/icons/doctor.svg";
import shakeHands from "../assets/icons/shakeHands.svg";
import dataCollection from "../assets/icons/dataCollection.svg";
import processIcon from "../assets/icons/process.svg";
import integrationIcon from "../assets/icons/computerIntegration.svg";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

declare global {
  interface Window {
    gtag_report_conversion: (url: string) => void;
  }
}

const LandingPage: React.FC = ()  => {
  const typeFormRegistrationForm = "https://8mt2mee3ezj.typeform.com/to/N0dg9emL";
  const typeFormContactUsForm = "https://8mt2mee3ezj.typeform.com/to/eR2meZA5";
  const [activeSection, setActiveSection] = useState("collect");

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
  
    return <button className=" text-sm block py-2 px-4 uppercase" onClick={() => loginWithRedirect()}>Log In</button>;
  };

  const trackConversion = (url: string) => {
    if (process.env.REACT_APP_ENV !== "production") {
      return;
    }

    if (window.gtag_report_conversion) {
      window.gtag_report_conversion(url);
      console.log(`Click through tracked: ${url}`);
    } else {
      console.error("gtag_report_conversion function is not available");
    }
  };

  const requestDemo = () => {
    window.open(typeFormRegistrationForm, "_blank");
    trackConversion(typeFormRegistrationForm);
  };

  return (
    <body className="antialiased bg-body text-body font-body">
      <div className="">
        <section className="relative overflow-hidden bg-brand-blue">
          <div className="absolute inset-0">
            <img src={headerBackground} alt="Header Background" className="w-full h-full" />
          </div>

          <section className="flex justify-center pb-4">
            <div className="container px-2 z-10">
              <div className="flex items-center justify-between pt-3">
                <h1 className="text-3xl block uppercase font-bold">ELVATI</h1>
                <div className="flex">
                  <LoginButton />
                  <button 
                    className="bg-brand-purple text-sm md:text-base text-white py-3 px-6 uppercase font-medium hover:bg-blue-800 rounded-full"
                    onClick={() => window.open(typeFormContactUsForm, "_blank")}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="relative container mx-auto px-2 pt-4 2xl:pt-8 z-10">
            <div className="flex justify-center z-10 ">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4 pb-4 sm:pb-10">
                <div className="md:col-span-5 flex flex-col justify-start items-center pr-4">
                  <div className="flex flex-col pr-4 pt-4 md:pt-20p">
                    <div className="inline-block relative pt-2 pb-1">
                      <p className="text-4xl font-serif text-brandBlue-900 sm:text-7xl 2xl:text-8xl font-extrabold leading-relaxed" style={{ lineHeight: "1.2" }}>
                        The Referral System For The Modern Healthcare Clinic
                      </p>
                    </div>
                    <p className=" pb-5 pt-4 text-brandBlue-900">We help Healthcare Clinics automate their referrals process, so they can focus on patient care.</p>
                    <div className="pb-4">
                      <button 
                        className="bg-brand-purple shadow text-sm sm:text-lg text-white py-4 px-7 uppercase font-medium hover:bg-blue-800 rounded-full uppercase"
                        onClick={() => requestDemo()}
                      >
                        Book a demo
                      </button>
                    </div>
                  </div>
                </div>
                <div className="md:col-span-7 sm:pt-0 pt-6 flex items-center">
                  <img src={headerRightImage} alt="" className="rounded-xl" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative container mx-auto px-2 sm:pt-14 pt-7 pb-10">
          <div className="flex justify-center">
            <div className="sm:max-w-6xl">
              <div className="flex flex-col items-center">
                <div className="bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-4 font-semibold">
                  <p className="text-center font-serif uppercase">Data Collection</p>
                </div>
                <p className="text-3xl font-serif sm:text-7xl font-semibold pb-4 text-center text-brandBlue-900">Step Into Real-Time Data Collection</p>
                <div className="flex justify-center">
                  <p className="text-center max-w-md">Healthcare clinics are plagued by poor data collection, resulting in wasted dollars</p>
                </div>
              </div>


              <div className="grid grid-cols-1 md:grid-cols-12 pb-10 gap-10  py-8 rounded-lg">
                <div className="md:col-span-6">
                  <img src={dataCollectionSuite} alt="" className="w-full" />
                </div>
                <div className="md:col-span-6">
                  <div className="pb-6">
                    <div className="flex">
                      <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                        <img src={phoneIcon} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                      </div>

                      <div className="flex flex-col">
                        <p className="text-2xl font-semibold font-serif">Over the phone</p>
                        <p className="pt-4">Elvati excels at handling over-the-phone referrals. Using our intuitive system, your staff can quickly send text messages during phone calls so your patient can upload there referral in real time, ensuring that no information is lost or overlooked.</p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-300 pb-6">
                    <div className="flex pt-6">
                      <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                        <img src={internetIcon} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-2xl font-semibold font-serif">Website</p>
                        <p className="pt-4">Our data collection suite seamlessly integrates with your clinic&apos;s website, making it simple for patients to submit referrals online. This easy-to-use interface ensures that referral information is accurately captured and instantly stored in your system.</p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-300">
                    <div className="flex pt-6">
                      <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                        <img src={doctorIcon} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-2xl font-semibold font-serif pt-6">Doctors</p>
                        <p className="pt-4">Streamline the process of receiving referrals from other healthcare professionals. Our system system integrate with existing infustrucutre and recevies hl7 files and other formats to easily and accurately capture referrals.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-brand-blue mt-3">
          <div className="relative container mx-auto md:py-12 2xl:max-w-6xl px-2">
            <div className="grid grid-cols-1 md:grid-cols-12 pb-10 gap-5 md:gap-20  py-8 rounded-lg">
              <div className="md:col-span-6">
                <div className="inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                  <p className="font-serif uppercase">Our vision</p>
                </div>
                <p className="text-3xl font-serif sm:text-7xl font-semibold pb-8 text-brandBlue-900">Effortless Online Referrals for Your Clinic</p>
                <p className="text-gray-800 pb-8">We provide a solution for Healthcare Clinics to automate their referral processes, streamlining administrative tasks and improving efficiency. By handling the time-consuming aspects of managing referrals, our system allows healthcare professionals to focus more on patient care.</p>

                <img src={yourPartners} />
              </div> 

              <div className="md:col-span-6">
                <img src={aboutUsImg} className="pb-8"/>

                <div className="flex ">
                  <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                    <img src={shakeHands} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                  </div>

                  <div className="flex flex-col">
                    <p className="text-2xl font-semibold font-serif pb-1 text-brandBlue-900">Your tech partner</p>
                    <p>As your tech partner, we work closely with you to understand and address your challenges, providing tailored solutions that streamline your referral processes and improve patient care.</p>
                  </div>
                </div>
              </div> 
            </div> 
          </div>
        </section>

        <section className="relative container mx-auto sm:pt-14 pt-7">
          <div className="flex justify-center">
            <div className="sm:max-w-6xl pb-10 px-2">
              <div className="flex justify-center pb-4">
                <div className="inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-4 font-semibold">
                  <p className="text-center font-serif uppercase">Dynamic referral management</p>
                </div>
              </div>
              <p className="text-9xl font-serif font-semibold pb-4 text-center text-brandBlue-900">The Elvati Schema</p>
              <p className="text-base text-center pb-6">Elvati Schema streamlines the referral management process by collecting, processing, and integrating referrals efficiently.</p>

              <ul className="flex font-medium text-center rounded-lg shadow sm:flex mb-8 uppercase cursor-pointer">
                <li className="w-full focus-within:z-10">
                  <span
                    onClick={() => setActiveSection("collect")}
                    className={`text-xs sm:text-base inline-block w-full p-3 ${
                      activeSection === "collect" ? "bg-brandBlue-200 text-brandBlue-900" : "text-brandBlue-900 bg-brand-blue hover:bg-gray-50"
                    } border-r border-white rounded-s-lg focus:ring-4`}
                    aria-current="page"
                  >
                    Collect
                  </span>
                </li>
                <li className="w-full focus-within:z-10">
                  <span
                    onClick={() => setActiveSection("process")}
                    className={`text-xs sm:text-base inline-block w-full p-3 ${
                      activeSection === "process" ? "bg-brandBlue-200 text-brandBlue-900" : "text-brandBlue-900 bg-brand-blue hover:bg-gray-50"
                    } border-r border-white focus:ring-4`}
                  >
                    Process
                  </span>
                </li>
                <li className="w-full focus-within:z-10">
                  <span
                    onClick={() => setActiveSection("integrate")}
                    className={`text-xs sm:text-base inline-block w-full p-3 ${
                      activeSection === "integrate" ? "bg-brandBlue-200 text-brandBlue-900" : "text-brandBlue-900 bg-brand-blue hover:bg-gray-50"
                    } border-r border-white rounded-r-lg focus:ring-4`}
                  >
                    Integrate
                  </span>
                </li>
              </ul>

              {activeSection === "collect" && (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-10 relative pt-7">
                  <div className="md:col-span-6 md:pb-12">
                    <img src={captureSvg} alt="Capture" />
                  </div>
                  <div className="md:col-span-6 flex flex-col relative">
                    <div className="flex h-full">
                      <div className="pl-4">
                        <div className="flex md:pt-6">
                          <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                            <img src={dataCollection} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                          </div>

                          <div className="flex flex-col pl-4">
                            <p className="text-5xl font-semibold font-serif pb-3 text-brandBlue-900">Collect</p>
                            <p>Elvati simplifies referral collection, making it easy to gather and organise patient referrals from various sources. It ensures accurate information capture, reducing paperwork and manual errors, and saving time for your clinic.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeSection === "process" && (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 relative pt-7">
                  <div className="md:col-span-6 md:pb-12">
                    <img src={processingSvg} alt="Processing" />
                  </div>

                  <div className="md:col-span-6 flex flex-col relative">
                    <div className="flex h-full">
                      <div className="pl-4">
                        <div className="flex md:pt-6">
                          <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                            <img src={processIcon} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                          </div>

                          <div className="flex flex-col pl-4">
                            <p className="text-5xl font-semibold font-serif pb-3 text-brandBlue-900">Process</p>
                            <p>After collection, Elvati processes referrals efficiently prioritizing, and matching them with appropriate state. Automated / manual workflows handle administrative tasks, ensuring prompt and accurate handling of referrals.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeSection === "integrate" && (
                <div className="grid grid-cols-1 md:grid-cols-12 gap-10 relative pt-7">
                  <div className="md:col-span-6 md:pb-12">
                    <img src={collectionSvg} alt="Collection" />
                  </div>

                  <div className="md:col-span-6 flex flex-col relative">
                    <div className="flex h-full">
                      <div className="pl-4">
                        <div className="flex md:pt-6">
                          <div className="w-6 h-6 p-10 bg-brand-purple rounded-full mr-3 flex items-center justify-center">
                            <img src={integrationIcon} alt="Capture" className="absolute" style={{ width: "40px", height: "40px", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(200%) contrast(100%)" }} />
                          </div>

                          <div className="flex flex-col pl-4">
                            <p className="text-5xl font-semibold font-serif pb-3 text-brandBlue-900">Integrate</p>
                            <p>Elvati integrates referral data into your clinic&apos;s existing systems, including updating EHR, scheduling appointments, and coordinating with medical staff. This automation ensures a smooth transition from referral to treatment, improving communication and patient care.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="bg-brand-blue">
          <div className="relative container mx-auto py-7">
            <div className="flex justify-center">
              <div className="sm:max-w-6xl px-2">
                <p className="font-serif text-9xl text-brandBlue-900  font-semibold leading-relaxed pb-8 pt-4">Explore the full story</p>

                <section className=" mt-3">
                  <div className="relative container mx-auto pb-12 2xl:max-w-6xl">
                    <div className="grid grid-cols-1 md:grid-cols-12 pb-10 gap-20  py-8 rounded-lg">

                      <div className="relative md:col-span-6 bg-white px-8 rounded-lg text-white" style={{ backgroundImage: "linear-gradient(134deg, #7b8ff5, #4764F5)" }}>
                        <img src={paymentsImg} alt="" className="rounded-lg w-72 relative" style={{ top: "-60px", height: "320px", width: "auto", objectFit: "cover" }} />
                        <div className="relative" style={{ top: "-40px" }}>
                          <div className="text-sm inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                            <p className="font-serif uppercase">Payments solution</p>
                          </div>
                          <p className="text-3xl sm:text-4xl pb-4 font-semibold">Say Goodbye to No-Show Losses</p>
                          <p className="">Authorise a holding payment at the time of booking, ensuring financial security for your clinic. This payment is released upon successful appointment completion or in the event of a no-show.</p>
                        </div>
                      </div>

                      <div className="relative md:col-span-6 bg-white px-8 rounded-lg text-white" style={{ backgroundImage: "linear-gradient(134deg, #CAB8A7, #A08A71)" }}>
                        <img src={paperlessSvg} alt="" className="rounded-lg w-72 relative" style={{ top: "-60px", height: "320px", width: "auto" }} />
                        <div className="relative" style={{ top: "-40px" }}>
                          <div className="text-sm inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                            <p className="font-serif uppercase">Paperless solution</p>
                          </div>
                          <p className="text-3xl  sm:text-4xl pb-4 font-semibold">Flexible Form Solutions for Clinics</p>
                          <p className="">Flexible form solutions enable you to create and customize any type of form, such as patient registration, to meet your clinic&apos;s unique needs. Design forms to capture essential information, streamline administrative processes, and enhance overall efficiency.</p>
                        </div>
                      </div>

                      <div className="relative md:col-span-6 bg-white px-8 rounded-lg text-white" style={{ backgroundImage: "linear-gradient(134deg, #599BAB, #204550)" }}>
                        <img src={brainAi} alt="" className="rounded-lg w-72 relative" style={{ top: "-60px", height: "320px", width: "auto", objectFit: "cover" }} />
                        <div className="relative" style={{ top: "-40px" }}>
                          <div className="text-sm inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                            <p className="font-serif uppercase">A.I Solution</p>
                          </div>
                          <p className="text-3xl  sm:text-4xl pb-4 font-semibold">Place A.I into the hands of your administrators</p>
                          <p className="">Empower your administrators with AI tools to streamline tasks and improve efficiency. Elvati is exploring AI solutions that automate routine processes and enhance decision-making capabilities.</p>
                        </div>
                      </div>

                      <div className="relative md:col-span-6 bg-white px-8 rounded-lg text-white" style={{ backgroundImage: "linear-gradient(134deg, #AB5980, #502040)" }}>
                        <img src={reportingImg} alt="" className="rounded-lg w-72 relative" style={{ top: "-60px", maxHeight: "320px", width: "auto", objectFit: "cover" }} />
                        <div className="relative" style={{ top: "-40px" }}>
                          <div className="text-sm inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                            <p className="font-serif uppercase">Reporting Solution</p>
                          </div>
                          <p className="text-3xl  sm:text-4xl pb-4 font-semibold">Comprehensive Reporting at Your Fingertips</p>
                          <p className="">Access detailed analytics and reports on all aspects of your referrals. Our comprehensive reporting tools provide insights into referral sources, processing times, and outcomes, helping you make informed decisions to optimize your clinic’s referral management and improve patient care.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-brandBlue-800">
          <div className="relative container mx-auto py-7">
            <div className="flex justify-center">
              <div className="sm:max-w-6xl px-2">
                <div className="flex flex-col md:flex-row justify-between md:items-start">
                  <div className="md:text-left md:mb-0">
                    <div className="text-sm inline-block bg-pink-100 text-pink-500 py-1 px-4 rounded-full mb-6 font-semibold">
                      <p className="font-serif uppercase">Our offer</p>
                    </div>
                    <p className="font-serif text-5xl md:text-9xl text-white font-semibold pb-8 text-brandBlue-900">
                      Try Elvati <span className="text-pink-500">Risk Free</span>
                    </p>
                  </div>

                  <div className="md:text-right pb-6">
                    <button
                      className="inline-block text-base py-4 px-7 font-medium rounded-lg hover:ring-4"
                      style={{ backgroundColor: "#5cff85" }}
                      onClick={() => requestDemo()}
                    >
                      Book A Demo
                    </button>
                  </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-12 pb-10 gap-5  pb-8 rounded-lg">
                  <div 
                    className="relative md:col-span-6 pb-8 border border-blue-900 rounded-lg p-6 bg-brandBlue-500 bg-cover bg-center" 
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url(${patientIpadImg})` }}
                   >
                    <div className="relative z-10">
                      <p className="font-serif text-5xl text-white font-semibold pb-4">
                        Start using it today!
                      </p>
                      <p className="text-white">
                        Start using our innovative solutions and experience the efficiency and ease of streamlined referral management.
                      </p>
                    </div>
                  </div>

                  <div className="md:col-span-3 pb-8 border border-blue-900 rounded-lg p-6 bg-brandBlue-500">
                    <p className="text-white pb-8 uppercase">
                      Risk free
                    </p>
                    <p className="font-serif text-8xl text-white  font-semibold pb-2">
                      60 Day
                    </p>

                    <p className="text-white">
                      money back guarantee
                    </p>
                  </div>
                  <div className="md:col-span-3 pb-8 border border-blue-900 rounded-lg p-6 bg-brandBlue-500">
                    <p className="text-white pb-8 uppercase">
                      Quick Setup
                    </p>
                    <p className="font-serif text-8xl text-white  font-semibold pb-2">
                      24 hours
                    </p>

                    <p className="text-white">
                      to start using Elvati
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="overflow-hidden bg-black">
          <div className="container mx-auto">
            <p className="text-white text-center py-8 text-sm">© Copyright {moment().year()} - All Rights Reserved by Elvati PTY LTD - ACN: 675 878 270</p>
          </div>
        </section>
      </div>
    </body>
  );
};

export default LandingPage;
