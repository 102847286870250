import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Patient } from "./MediaPatient";

export const referralStatusService = createApi({
  reducerPath: "referralStatusService",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_HOST_URL }),
  endpoints: (builder) => ({
    getReferralStatuses: builder.query<
      ReferralStatusResponse,
      { tenantSlug: string; accessToken: string }
    >({
      query: ({ tenantSlug, accessToken }) => ({
        url: "/referral_status",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Tenant-Slug": tenantSlug,
        },
      }),
    }),
  }),
});

export const { useGetReferralStatusesQuery } = referralStatusService;

export interface ReferralStatusResponse {
  data: ReferralStatus[] | null;
  error: string | null;
}

export interface ReferralStatus extends Patient {
  id: string;
  name: string;
  isDefault: boolean;
  index: number;
  metadata: {
    color: string;
    iconName: string;
  };
  createdAt: string;
  updatedAt: string;
}
