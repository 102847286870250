import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import HL7FileReferral from "../Hl7FileReferral";
import { PatientDetails } from "../DisplayHl7";
import printerIcon from "../../../../assets/icons/printer.svg";

type PrintThisHtmlProps = {
  children?: React.ReactNode;
};

interface PrintReferralProps {
  patientDetails: PatientDetails;
}

const PrintThisHtml = React.forwardRef<HTMLDivElement, PrintThisHtmlProps>((props, ref) => (
  <div ref={ref} className="m-4">
    {props.children}
  </div>
));

PrintThisHtml.displayName = "PrintThisHtml";

const PrintHl7FileReferral: React.FC<PrintReferralProps> = ({ patientDetails }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPrinting, setIsPrinting] = React.useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    }
  });

  return (
    <div>
      <button className="absolute pr-4 border rounded-full border-black py-1 px-3 mr-4" style={ {top: "-65px", right: "0px" } } onClick={ () => handlePrint() }>
        <div className="flex items-center">
          <img src={printerIcon} alt="Camera Icon" className="w-6 h-6" />
          Print
        </div>
      </button>

      <PrintThisHtml ref={componentRef}>
        <HL7FileReferral 
          patientDetails={patientDetails} 
        />
      </PrintThisHtml>
    </div>
  );
};

export default PrintHl7FileReferral;
