import moment from "moment";
import React from "react";
import { PatientDetails, RequestedServices } from "./DisplayHl7";

export interface PatientDetailsProps {
  patientDetails: PatientDetails;
}

const HL7FileReferral: React.FC<PatientDetailsProps> = ({ patientDetails }) => {
  return (
  <div className="border border-gray-200 bg-blue-100 rounded-lg p-3 mt-6">
    <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-12">
      <div className="sm:col-span-6 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Last name</label>
        <p className="font-bold">{patientDetails?.lastName}</p>
      </div>
      <div className="sm:col-span-6 bg-white  p-2 rounded-lg">
        <label className="block text-sm font-medium leading-6 text-gray-900">First name</label>
        <div className="mt-2">
          <p className="font-bold">{patientDetails?.firstName}</p>
        </div>
      </div>
      <div className="sm:col-span-3 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Date of birth</label>
        <p className="font-bold">{moment(patientDetails?.dateOfBirth).local().format("DD/MM/YYYY")}</p>
      </div>
      <div className="sm:col-span-2 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Age</label>
        <p className="font-bold">{ moment().diff(moment(patientDetails?.dateOfBirth), "years")}</p>
      </div>
      <div className="sm:col-span-2 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Sex</label>
        <p className="font-bold">{patientDetails?.sex}</p>
      </div>
      <div className="sm:col-span-5 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Medicare</label>
        <p className="font-bold">{patientDetails?.medicareNumber}</p>
      </div>
      <div className="sm:col-span-6 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Mobile Phone</label>
        <p className="font-bold">{patientDetails?.mobilePhoneNumber}</p>
      </div>
      <div className="sm:col-span-6 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Email</label>
        <p className="font-bold">{patientDetails?.email}</p>
      </div>
      <div className="sm:col-span-12 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Address</label>
        <p className="font-bold">{patientDetails?.address}, {patientDetails?.city}, {patientDetails?.state}, {patientDetails?.zipCode}</p>
      </div>
      <div className="sm:col-span-4 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Provider Number</label>
        <p className="font-bold">{patientDetails?.providerNumber}</p>
      </div>
      <div className="sm:col-span-4 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Facility</label>
        <p className="font-bold">{`Dr ${patientDetails?.doctorFirstName} ${patientDetails?.doctorLastName}`}</p>
        <p className="font-bold">{patientDetails?.facilityName}</p>
      </div>
      <div className="sm:col-span-4 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900">Observation Date</label>
        <p className="font-bold">{moment(patientDetails?.observationDateTime, "YYYYMMDDHHmmssZ").format("hh:mm:ss a DD/MM/YYYY")}</p>
      </div>
      <div className="sm:col-span-12 bg-white  p-2 rounded-lg">
        <label  className="block text-sm font-medium leading-6 text-gray-900 pb-3">Requested Services</label>
        {
          patientDetails?.requestedServices?.map((service: RequestedServices, index: number) => (
            <div className="flex mb-3" key={index}>
              <p className="flex px-3 pb-3 mr-3">{index + 1}</p>
              <div className="bg-white rounded-lg" key={service?.universalServiceId}>
                <p className="font-bold">{service?.universalServiceId}</p>
                <p className="">{service?.relevantClinicInfo}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </div>
  );
};

export default HL7FileReferral;