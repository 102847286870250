import SegmentBase from "./SegmentBase";

export default class PIDSegment extends SegmentBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(hash: any) {
    const contactDetails = this.pluckValues(hash["data"]["PID.13"]);
    return [
      { "field_name": "PID-5", "field_description": "Patient Name", "value": this.pluckValues(hash["data"]["PID.5"]) },
      { "field_name": "PID-7", "field_description": "Date/Time of Birth", "value":  this.pluckValues(hash["data"]["PID.7"]) },
      { "field_name": "PID-11", "field_description": "Contact Details", "value": contactDetails },
      { "field_name": "PID-8", "field_description": "Administrative Sex", "value":  this.pluckValues(hash["data"]["PID.8"]) },
      { "field_name": "PID-11", "field_description": "Patient Address", "value": this.pluckValues(hash["data"]["PID.11"]) },
      { "field_name": "PID-12", "field_description": "County Code", "value":  this.pluckValues(hash["data"]["PID.12"]) },
      { "field_name": "PID-6", "field_description": "Mother's Maiden Name", "value": this.pluckValues(hash["data"]["PID.6"]) },
      { "field_name": "PID-1", "field_description": "Set ID - PID", "value":  this.pluckValues(hash["data"]["PID.1"]) },
      { "field_name": "PID-2", "field_description": "Patient ID", "value":  this.pluckValues(hash["data"]["PID.2"]) },
      { "field_name": "PID-3", "field_description": "Patient Identifier List", "value":  this.pluckValues(hash["data"]["PID.3"]) },
      { "field_name": "PID-4", "field_description": "Alternate Patient ID - PID", "value":  this.pluckValues(hash["data"]["PID.4"]) },
      { "field_name": "PID-9", "field_description": "Patient Alias", "value":  this.pluckValues(hash["data"]["PID.9"]) },
      { "field_name": "PID-10", "field_description": "Race", "value":  this.pluckValues(hash["data"]["PID.10"]) },
      // Continue for the remaining fields
    ];
  }
}
