import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const SideNavBar: React.FC = ()  => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return(
    <nav 
      className="fixed top-0 left-0 bottom-0 flex flex-col w-3/4 lg:w-80 sm:max-w-xs pt-6 pb-8 overflow-y-auto bg-white" 
      style={{
        borderRight: "1px solid #e4e4e4",
        width: "90px"
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h2 className="text-xl font-bold" style={{"paddingBottom": "14px"}}>Elvati</h2>
      </div>
      <div className="pb-6 h-full">
        <ul className="mb-8 text-sm font-medium">
          <li>
            <button onClick={() => navigate("/referrals") } className="flex flex-col py-4 justify-center items-center w-full hover:bg-gray-100">
              <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
              </svg>
              <span className="text-black text-xs">Referrals</span>
            </button>
          </li>
        </ul>
        <div className="absolute bottom-0 left-0 w-full text-sm font-medium">
          <button className="flex flex-col py-4 justify-center items-center w-full hover:bg-gray-100" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>

            <span className="text-black text-xs">Log out</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default SideNavBar;
