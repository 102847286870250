// BaseService.ts
import { useAuth0 } from "@auth0/auth0-react";
import store from "../redux/store";
import { CurrentUser } from "./User";

export async function getAccessToken(): Promise<string | null> {
  try {
    const { getIdTokenClaims } = useAuth0(); // Move this line to where it's used within a component
    const tokenClaims = await getIdTokenClaims();
    const accessToken = tokenClaims?.__raw;
    return accessToken || null;
  } catch (error) {
    console.error("Error retrieving access token:", error);
    return null;
  }
}

export async function currentUser(): Promise<CurrentUser> {
  return store.getState().currentUser;
}
