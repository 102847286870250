interface DataResponse {
  data: BookingPreference | null;
  error: string | null;
}

export interface BookingPreference {
  id: string;
  referralId: string;
  date: string;
  time: string;
  createdAt: string;
  updatedAt: string;
}

interface CreateParams {
  tenantId: string;
  referralId: string;
  date: string;
  time: string;
}

export default class BookingPreferenceService {
  static async create(params: CreateParams): Promise<DataResponse> {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST_URL}/referrals/${params.referralId}/booking_preferences`, {
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": params.tenantId,
        },
        method: "POST",
        body: JSON.stringify(params),
      }
    );
  
    const responseData: DataResponse = await response.json();

    if (response.ok) {
      return { data: responseData.data, error: null };
    } else {
      return { data: null, error: "Upload failed" };
    }
  }
}
