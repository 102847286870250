import React from "react";

export interface Params {
  heading: string;
  redirectBack?: () => void;
}

const TopBar: React.FC<Params> = (props: Params) => {
  return (
    <div className="relative h-12 flex items-center border-b">
      {props.redirectBack && (
        <button className="absolute left-0 ml-4" onClick={props.redirectBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      )}
      <span className="mx-auto text-sm font-bold">{props.heading}</span>
    </div>
  );
};

export default TopBar;
