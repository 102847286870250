import SegmentBase from "./SegmentBase";

export default class ORCSegment extends SegmentBase {
  parse(hash: never) {
    return [
      { "field_name": "ORC-1", "field_description": "Order Control", "value": this.pluckValues(hash["data"]["ORC.1"]) },
      { "field_name": "ORC-2", "field_description": "Placer Order Number", "value": this.pluckValues(hash["data"]["ORC.2"]) },
      { "field_name": "ORC-3", "field_description": "Filler Order Number", "value": this.pluckValues(hash["data"]["ORC.3"]) },
      { "field_name": "ORC-4", "field_description": "Placer Group Number", "value": this.pluckValues(hash["data"]["ORC.4"]) },
      { "field_name": "ORC-5", "field_description": "Order Status", "value": this.pluckValues(hash["data"]["ORC.5"]) },
      { "field_name": "ORC-6", "field_description": "Response Flag", "value": this.pluckValues(hash["data"]["ORC.6"]) },
      { "field_name": "ORC-7", "field_description": "Quantity/Timing", "value": this.pluckValues(hash["data"]["ORC.7"]) },
    ];
  }
}
