import React, { useState, useEffect } from "react";
import S3Service from "../services/aws/S3Service";

interface ImageDisplayProps {
  imageKey: string;
  className?: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageKey, className }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const fetchImageUrl = async () => {
    const url = await S3Service.getPresignedUrl(imageKey, 2);
    setImageUrl(url);
    return url;
  };

  useEffect(() => {
    fetchImageUrl();
  }, [imageKey]);

  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          className={className}
          onClick={ async () =>  {
            const url = await fetchImageUrl();
            if (url) {
              window.open(url, "_blank");  
            }
          }}
        />
      ) : (
        <div className="flex justify-center">
          <span className="inline-block h-12 w-12 animate-spin text-gray-400 rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        </div>
      )}
    </>
  );
};

export default ImageDisplay;
