export interface Location {
  id: string;
  tenantId: string;
  isDefault: boolean;
  name: string;
  slug: string;
  streetAddress: string;
  suburb: string;
  state: string;
  country: string;
  postCode: string;
  landline?: string;
  email: string;
  website: string;
  logoUrl: string;
  metadata: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  coordinates?: Coordinates | null;
  createdAt: string;
  updatedAt: string;
}

interface Coordinates {
  lat?: string | null;
  lng?: string | null;
}

export const toAddress = (location: Location) => {
  return `${location.streetAddress || ""}, ${location.suburb || ""}, ${location.state || ""}, ${location.postCode || ""}`;
};
