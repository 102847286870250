// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v4: uuidv4 } = require("uuid");

interface UploadResponse {
  filePath: string | null;
  error: string | null;
}

interface Params {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any;
  slug: string; // clinic slug
}

export const awsFileUpload = async (params: Params): Promise<UploadResponse> => {
  const folderName = uuidv4();
  const requestBody = {
    folderName: `referrals/patient_upload_image_client/${folderName}`,
    clinicSlug: params.slug,
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  };

  // Send request to Lambda function to get signed URL
  const response = await fetch(
    `${process.env.REACT_APP_AWS_SIGNED_URL}`, {
      headers: {
        "Content-Type": params.file.type,
      },
      method: "POST",
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    return { filePath: null, error: "Upload failed" };
  }

  const data = await response.json();
  const { uploadURL, filePath } = data;

  // Use the obtained signed URL to upload the file
  const uploadResponse = await fetch(uploadURL, {
    method: "PUT",
    body: params.file,
    headers: {
      "Content-Type": params.file.type,
    },
  });

  if (uploadResponse.ok) {
    return { filePath: filePath, error: null };
  } else {
    return { filePath: null, error: "Upload failed" };
  }
};
