import { ActivityLog } from "./ActivityLogsService";
import { BookingPreference } from "./BookingPreferenceService";
import { CurrentUser } from "./User";
import { User } from "./UserService";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Location } from "../models/Location";
import store from "../redux/store";

interface DataResponse {
  data: Referral | null;
  error: string | null;
}

interface IndexDataResponse {
  data: {
    referrals: Referral[];
    totalCount: number;
  };
  error: string | null;
}
interface CreateParams {
  url: string;
  recipientEmail: string; // the email of the clinic
  mobilePhoneNumber: string;
  locationId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  origin: ReferralOrigins;
}

export enum ReferralOrigins {
  patient_upload_image_client = "patient_upload_image_client",
  company_website = "company_website",
  apollo_client = "apollo_client",
}

export interface PatchParams {
  referralStatusId: string | null;
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  medicareNumber: string | null;
  dateOfBirth: string | null;
  email: string | null;
}

export interface Patient {
  id: string;
  firstName: string | "";
  lastName: string | "";
  address: string | "";
  city: string | "";
  state: string | "";
  zipCode: string | "";
  medicareNumber: string | "";
  dateOfBirth: string | "";
  email: string | "";
  mobilePhoneNumber: string | "";
  createdAt: string;
  updatedAt: string;
  activityLogs?: ActivityLog[];
}

export interface Referral {
  id: string;
  url: string;
  status: string;
  origin: string;
  patient: Patient;
  location: Location;
  user?: User;
  bookingPreference?: BookingPreference;
  createdAt: string;
  updatedAt: string;
}

export const mediaPatientApi = createApi({
  reducerPath: "mediaPatientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      headers.set("Tenant-Slug", "elvati");

      return headers;
    },
  }),
  refetchOnMountOrArgChange: 2,
  endpoints: (builder) => ({
    getReferrals: builder.query<
      IndexDataResponse,
      {
        itemsPerPage: number;
        itemOffset: number;
        status: string | null;
        userId: string | null;
        origin: ReferralOrigins | null;
        locationIds: string[] | null;
        searchQuery: string | null;
        dateOfBirth: string | null;
      }
    >({
      query: ({
        itemsPerPage,
        itemOffset,
        status,
        userId,
        origin,
        locationIds,
        searchQuery,
        dateOfBirth,
      }) => {
        console.log(
          "init query",
          itemsPerPage,
          itemOffset,
          status,
          userId,
          origin,
          locationIds,
          searchQuery,
          dateOfBirth,
        );
        let apiUrl = `/referrals?itemsPerPage=${itemsPerPage}&itemOffset=${itemOffset}`;

        if (status) {
          apiUrl += `&state=${status}`;
        }
        if (userId) {
          apiUrl += `&userId=${userId}`;
        }
        if (origin) {
          apiUrl += `&origin=${origin}`;
        }
        if (searchQuery) {
          apiUrl += `&query=${encodeURIComponent(searchQuery)}`;
        }
        if (dateOfBirth) {
          apiUrl += `&dateOfBirth=${dateOfBirth}`;
        }
        if (locationIds) {
          apiUrl += `&locationIds=${locationIds.join(",")}`;
        }

        return {
          url: apiUrl,
          method: "GET",
          // Headers set in prepareHeaders will be included by default
        };
      },
      keepUnusedDataFor: 2,
    }),
  }),
});

export const { useGetReferralsQuery } = mediaPatientApi;

export default class MediaPatientAPI {
  static async delete(
    currentUser: CurrentUser,
    accessToken: string | undefined,
    id: string,
  ): Promise<DataResponse> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/referrals/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Tenant-Slug": currentUser.tenantSlug,
        },
        method: "DELETE",
      },
    );

    const responseData = await response.json();
    if (response.ok) {
      return { data: responseData.data, error: null };
    } else {
      return { data: null, error: responseData.error };
    }
  }

  static async get(
    currentUser: CurrentUser,
    accessToken: string | undefined,
    id: string,
  ): Promise<DataResponse> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/referrals/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Tenant-Slug": currentUser.tenantSlug,
        },
        method: "GET",
      },
    );

    if (response.ok) {
      const responseData = await response.json();
      const mediaPatient: Referral = responseData.data;
      return { data: mediaPatient, error: null };
    } else {
      const responseData = await response.json();
      console.log("Failed to fetch media patient", responseData);
      return { data: null, error: responseData.error };
    }
  }

  static async create(
    params: CreateParams,
    slug: string,
  ): Promise<DataResponse> {
    console.log("hit create params", params);
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/referrals`,
      {
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": slug,
        },
        method: "POST",
        body: JSON.stringify(params),
      },
    );

    const responseData = await response.json();

    if (response.ok) {
      return { data: responseData.data, error: null };
    } else {
      return { data: null, error: "Upload failed" };
    }
  }

  static async update(
    currentUser: CurrentUser,
    accessToken: string | null,
    id: string,
    params: PatchParams,
  ): Promise<DataResponse> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST_URL}/referrals/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Tenant-Slug": currentUser.tenantSlug,
        },
        method: "PATCH",
        body: JSON.stringify(params),
      },
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("success", responseData.error);
      const mediaPatient: Referral = responseData.data;
      return { data: mediaPatient, error: null };
    } else {
      const responseData = await response.json();
      console.log("Failed to fetch media patient", responseData);
      return { data: null, error: responseData.error };
    }
  }
}
